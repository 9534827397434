import React, { useState, useEffect, useRef } from 'react';

import { AgGridReact } from 'ag-grid-react'; // AG Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import "ag-grid-enterprise";
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';

import { adservRatio, percentageOf, percentageFormatter, currencyFormatter, cleanNulls, dateFormatterWithPST } from '../valueformatter';

// Splits entity overview component
export default function SplitsEntityOverviewV2(props) {
  const gridRef = useRef();
  // Row Data: The data to be displayed.
  const [rowData, setRowData] = useState([]);
  // Grid Options
  const gridOptions = {
    sideBar: true,
    rowGroupPanelShow: 'always',
    // groupDefaultExpanded: 1,
    columnHoverHighlight: true,
    // groupIncludeFooter: true,
    groupIncludeTotalFooter: true,
    suppressAggFuncInHeader: true,
    autoGroupColumnDef: {
      cellRendererParams: {
        footerValueGetter: params => {
          const isRootLevel = params.node.level === -1;
          if (isRootLevel) {
            return 'Grand Total';
          }
          return `Sub Total (${params.value})`;
        },
      },
      headerName: 'Company',
    },
    getRowStyle: params => {
      // Grand Total row formatting
      if (params.node.group === true && params.node.level === -1) {
        return { background: '#3F91B8', color: 'white', fontWeight: 'bold' };
        // #b5c4ca
        // #99bac9
      }
      // Subtotal row formatting
      else if (params.node.group === true) {
        return { background: '#b5c4ca', fontWeight: 'bold' };
      }
    },
    autoSizeStrategy: {
      type: 'fitCellContents'
    },
  };


  // DefaultColDef sets props common to all Columns
  const defaultColDef = {
    filter: true,
    cellStyle: { textAlign: 'right' }
  };

  // Group agg func
  const getGroupRowAgg = (params) => {
    const result = {
      contact_start_pst: 0,
      cost_per_call: 0,
    }
    // Total up row values/make calcs
    params.nodes.forEach((node) => {
      const data = node.group ? node.aggData : node.data
      // Increment Call Count
      result.contact_start_pst += 1;
      // Increment Cases Created
      result.cost_per_call += data.cost_per_call;
    });

   

    return result;
  };

  /*
    Row Object Example
    "call_cost": null,
    "call_count": null,
    "cases_created": null,
    "company": "Las Vegas",
    "deals": null,
    "ip": null,
    "settlement_officer": "Rich Nestor",
    "tp": -50
  */

  // Column Definitions: Defines the columns to be displayed.
  const colDefs = [
    {
      field: "team_name", headerName: "Company", sort: "asc", rowGroup: true,
      enableRowGroup: true, hide: true, cellStyle: { textAlign: 'left' }
    },
    { field: "agent_name", headerName: "Set. Officer", cellStyle: { textAlign: 'left' }, sort: "asc", rowGroup:true, enableRowGroup: true },
    { field: "contact_start", headerName: "Contact Start", 
        valueFormatter: params => dateFormatterWithPST(params.value)
     },
    { field: "cost_per_call", headerName: "Cost Per Call", aggFunc: 'sum', 
        valueFormatter: params => currencyFormatter(params.value, "$")
    },
  ];

  // Preserving Filter Model
  const [filterModel, setFilterModel] = useState(null);  // Save current filters

  // Loading state to track when data is being fetched
  const [loading, setLoading] = useState(true);

  // Fetch data based on date range
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Set loading to true before fetching
      if (gridRef.current) {
        // Save current filter state
        const currentFilterModel = gridRef.current.api.getFilterModel();
        setFilterModel(currentFilterModel);
      }

      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      };
      const reqUrl = `${process.env.REACT_APP_API_URL}/api/reports?report=${props.reportName}&startDate=${props.startDate}&endDate=${props.endDate}`;

      try {
        const result = await fetch(reqUrl, requestOptions);
        const gridData = await result.json();
        setRowData(gridData.data);
      } catch (error) {
        console.error("ERROR: " + error);
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    };

    fetchData();
  }, [props.reportName, props.startDate, props.endDate]);

  // After new data is set, reapply the filter model
  useEffect(() => {
    if (gridRef.current && filterModel) {
      gridRef.current.api.setFilterModel(filterModel);
    }
  }, [rowData]);

  // If rows have not yet been returned
  // if (loading) {
  //   return (
  //     <div>
  //       <h1 className='color2-font'>DATA LOADING</h1>
  //     </div>
  //   )
  // }
  

  return (
    // wrapping container with theme & size
    <div
      className="ag-theme-quartz h-60 m-top-3 greyBg" // applying the grid theme
    // style={{ overflow: 'hidden' }} // the grid will fill the size of the parent container
    >
      <div id="summaryBox">
        <p id="summaryText">
          <strong>Splits Entity Overview V2</strong><br />
          This view will show data for the cases created or sold within the filtered date range.
          <br />
          <ul>
            <li>Payment totals are filtered via paid date and are <strong>independent</strong> of the sale date of the case.</li>
            <li>Splits are taken into consideration to provide a more accurate credit to each Settlement Officer.</li>
          </ul>
        </p>
      </div>
      <div style={{ position: 'relative', height: '100%' }}> {/* New wrapper for AG Grid */}
        <AgGridReact
          enableCharts={true}
          enableRangeSelection={true}
          gridRef={gridRef}
          rowData={rowData}
          columnDefs={colDefs}
          getGroupRowAgg={getGroupRowAgg}
          gridOptions={gridOptions}
          defaultColDef={defaultColDef}
          // groupIncludeTotalFooter={groupIncludeTotalFooter}
          modules={[ServerSideRowModelModule, RowGroupingModule]}
        />
        {loading && (
            <div className="loading-overlay">
            <div className="spinner"></div>
            </div>
        )}
      </div>
    </div>
  )
}