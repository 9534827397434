import React, { useState, useEffect, useRef } from 'react';

import { AgGridReact } from 'ag-grid-react'; // AG Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import './LogicsActivity.css'
import "ag-grid-enterprise";
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';

import { dateFormatterWithAMPM } from '../valueformatter';

export default function LogicsActivity(props) {
  const gridOptions = {
    sidebar: true,
    rowGroupPanelShow: 'always',
    groupDefaultExpanded: 2,
    columnHoverHighlight: true,
    // groupIncludeFooter: true,
    groupIncludeTotalFooter: true,
    suppressAggFuncInHeader: true,
    autoGroupColumnDef: {
      cellRendererParams: {
        footerValueGetter: params => {
          const isRootLevel = params.node.level === -1;
          // console.log("isRootLevel: ", isRootLevel);
          if (isRootLevel) {
            return 'Grand Total';
          }
          return `Sub Total (${params.value})`;
        },
      },
      headerName: 'Source',
    },
    getRowStyle: params => {
      // Grand Total row formatting
      if (params.node.group === true &&params.node.level === -1) {
        return { background: '#3F91B8', color: 'white', fontWeight: 'bold' };
        // #b5c4ca
        // #99bac9
      }
      // Subtotal row formatting
      else if (params.node.group === true) {
        return { background: '#b5c4ca', fontWeight: 'bold' };
      }
    },
    autoSizeStrategy: {
      type: 'fitCellContents'
    },
  };

  const gridRef = useRef(); // Optional - for accessing Grid's API

  // DefaultColDef sets props common to all Columns
  const defaultColDef = {
    filter: true,
  };


  console.log("props: ", props);
  /*
    Row Object Example
    {
      "settlement_offcer":"Roger Ruiz",
      "logics":"GTRL",
      "tag":"Initial Payment",
      "payment_method":"Refund",
      "case_id":"12345",
      "paid_date":"2024-03-26",
      "amount":"-2392.00",
      "last_activity":"Roger Ruiz: Updated Payment Method",
      "last_activity_date":"2024-03-26",
    }
  */

  // Column Definitions: Defines the columns to be displayed.
  const colDefs = [
    { field: "marketing_line_desc", headerName: "Source", sort: "asc", enableRowGroup: true, rowGroup: true, hide: true, },
    { field: "case_number", headerName: "Case Number", sort: "asc", enableRowGroup: true, rowGroup: true, hide: true, },
    { field: "subject", headerName: "Subject", sort: "asc", enableRowGroup: true, },
    { field: "type", headerName: "Type", sort: "asc", enableRowGroup: true, },
    { field: "created_by", headerName: "Created By", sort: "asc", enableRowGroup: true, },
    { field: "created_date", headerName: "Created Date", sort: "desc", enableRowGroup: true, 
    valueFormatter: params => dateFormatterWithAMPM(params.value), cellStyle: { textAlign: 'right' }
    },
  ];

  // Row Data: The data to be displayed.
  const [rowData, setRowData] = useState([]);

  // Preserving Filter Model
  const [filterModel, setFilterModel] = useState(null);  // Save current filters

  // Loading state to track when data is being fetched
  const [loading, setLoading] = useState(true);

  // Fetch data based on date range
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Set loading to true before fetching
      if (gridRef.current) {
        // Save current filter state
        const currentFilterModel = gridRef.current.api.getFilterModel();
        setFilterModel(currentFilterModel);
      }

      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      };
      const reqUrl = `${process.env.REACT_APP_API_URL}/api/reports?report=${props.reportName}&startDate=${props.startDate}&endDate=${props.endDate}`;

      try {
        const result = await fetch(reqUrl, requestOptions);
        const gridData = await result.json();
        setRowData(gridData.data);
      } catch (error) {
        console.error("ERROR: " + error);
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    };

    fetchData();
  }, [props.reportName, props.startDate, props.endDate]);

  // After new data is set, reapply the filter model
  useEffect(() => {
    if (gridRef.current && filterModel) {
      gridRef.current.api.setFilterModel(filterModel);
    }
  }, [rowData]);

  // If rows have not yet been returned
  // if (loading) {
  //   return (
  //     <div>
  //       <h1 className='color2-font'>DATA LOADING</h1>
  //     </div>
  //   )
  // }
  
  return (
    // wrapping container with theme & size
    <div
      className="ag-theme-quartz h-60 m-top-3 greyBg" // applying the grid theme
      // style={{ height: 100% }} // the grid will fill the size of the parent container
    >
      <div id="summaryBox">
        <p id="summaryText">
          <strong>Logics Activity</strong><br/>
          This view displays a summary of the logics activity for form fills. It is grouped by Source and Case Number.
        </p>
      </div>
      <div style={{ position: 'relative', height: '100%' }}> {/* New wrapper for AG Grid */}
        <AgGridReact
          enableCharts={true}
          enableRangeSelection={true}
          rowData={rowData}
          columnDefs={colDefs}
          gridOptions={gridOptions}
          defaultColDef={defaultColDef}
          modules={[ServerSideRowModelModule, RowGroupingModule]}
          // rowGroupPanelShow={rowGroupPanelShow}
        />
        {loading && (
            <div className="loading-overlay">
            <div className="spinner"></div>
            </div>
        )}
      </div>
    </div>
  )
}