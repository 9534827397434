import React, { useState, useEffect, useRef } from 'react';

import { AgGridReact } from 'ag-grid-react'; // AG Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import "ag-grid-enterprise";
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';

import { adservRatio, percentageOf, percentageFormatter, currencyFormatter, cleanNulls } from '../valueformatter';

// Splits entity overview component
export default function SplitsEntityOverview(props) {
  const gridRef = useRef();
  // Row Data: The data to be displayed.
  const [rowData, setRowData] = useState([]);
  // Grid Options
  const gridOptions = {
    sideBar: true,
    rowGroupPanelShow: 'always',
    // groupDefaultExpanded: 1,
    columnHoverHighlight: true,
    // groupIncludeFooter: true,
    groupIncludeTotalFooter: true,
    suppressAggFuncInHeader: true,
    autoGroupColumnDef: {
      cellRendererParams: {
        footerValueGetter: params => {
          const isRootLevel = params.node.level === -1;
          if (isRootLevel) {
            return 'Grand Total';
          }
          return `Sub Total (${params.value})`;
        },
      },
      headerName: 'Company',
    },
    getRowStyle: params => {
      // Grand Total row formatting
      if (params.node.group === true && params.node.level === -1) {
        return { background: '#3F91B8', color: 'white', fontWeight: 'bold' };
        // #b5c4ca
        // #99bac9
      }
      // Subtotal row formatting
      else if (params.node.group === true) {
        return { background: '#b5c4ca', fontWeight: 'bold' };
      }
    },
    autoSizeStrategy: {
      type: 'fitCellContents'
    },
  };


  // DefaultColDef sets props common to all Columns
  const defaultColDef = {
    filter: true,
    cellStyle: { textAlign: 'right' }
  };

  // Group agg func
  const getGroupRowAgg = (params) => {
    const result = {
      call_count: 0,
      cases_created: 0,
      deals: 0,
      cases_calls: 0,
      deals_calls: 0,
      deals_cases: 0,
      call_cost: 0,
      ip: 0,
      ip_roi: 0,
      tp: 0,
      tp_roi: 0,
      ip_loan: 0,
      ip_loan_percent: 0,
    }
    // Total up row values/make calcs
    params.nodes.forEach((node) => {
      const data = node.group ? node.aggData : node.data
      // Increment Call Count
      result.call_count += data.call_count;
      // Increment Cases Created
      result.cases_created += data.cases_created;
      // Increment Deals
      result.deals += data.deals;
      // Increment Call Cost
      result.call_cost += data.call_cost;
      // Increment IP
      result.ip += data.ip;
      // Increment TP
      result.tp += data.tp;
      // Increment IP Loan Amount
      result.ip_loan += data.ip_loan;
    });

    // Calc Cases/Calls
    if (result.call_count !== 0) {
      result.cases_calls = ((result.cases_created / result.call_count) * 100);
    }

    // Calc Deals/Calls
    if (result.call_count !== 0) {
      result.deals_calls = ((result.deals / result.call_count) * 100);
    }

    // Calc Deals/Cases
    if (result.cases_created !== 0) {
      result.deals_cases = ((result.deals / result.cases_created) * 100);
    }

    // Calc IP ROI
    if (result.call_cost !== 0) {
      result.ip_roi = parseFloat((result.ip / result.call_cost).toFixed(2));
    }

    // Calc TP ROI
    if (result.call_cost !== 0) {
      result.tp_roi = parseFloat((result.tp / result.call_cost).toFixed(2));
    }

    // Calc IP Loan %
    if (result.ip !== 0) {
      result.ip_loan_percent = parseFloat((result.ip_loan / result.ip * 100).toFixed(2));
    }

    return result;
  };

  /*
    Row Object Example
    "call_cost": null,
    "call_count": null,
    "cases_created": null,
    "company": "Las Vegas",
    "deals": null,
    "ip": null,
    "settlement_officer": "Rich Nestor",
    "tp": -50
  */

  // Column Definitions: Defines the columns to be displayed.
  const colDefs = [
    {
      field: "company", headerName: "Company", sort: "asc", rowGroup: true,
      enableRowGroup: true, hide: true, cellStyle: { textAlign: 'left' }
    },
    { field: "settlement_officer", headerName: "Set. Officer", cellStyle: { textAlign: 'left' }, sort: "asc" },
    { field: "call_count", headerName: "Lead Count", aggFunc: 'sum', },
    { field: "cases_created", headerName: "Cases Created", aggFunc: 'sum' },
    { field: "deals", headerName: "Deals", aggFunc: 'sum' },
    {
      field: "cases_calls",
      headerName: "Cases/Calls",
      valueGetter: params => percentageOf(params.data.cases_created, params.data.call_count),
      valueFormatter: params => percentageFormatter(params.value),
    },
    {
      field: "deals_calls",
      headerName: "Deals/Calls",
      valueGetter: params => percentageOf(params.data.deals, params.data.call_count),
      valueFormatter: params => percentageFormatter(params.value)
    },
    {
      field: "deals_cases",
      headerName: "Deals/Cases",
      valueGetter: params => percentageOf(params.data.deals, params.data.cases_created),
      valueFormatter: params => percentageFormatter(params.value)
    },
    {
      field: "call_cost",
      headerName: "Lead Cost",
      valueFormatter: params => currencyFormatter(params.value, "$")
    },
    {
      field: "ip",
      headerName: "IP",
      valueFormatter: params => currencyFormatter(params.value, "$"),
      headerTooltip: "Initial Payments with paid date within the date range",
      tooltipValueGetter: () =>
        "Initial Payments with paid date within the date range",
    },
    {
      field: "ip_roi",
      headerName: "IP ROI",
      valueGetter: params => adservRatio(params.data.ip, params.data.call_cost)

    },
    {
      field: "tp",
      headerName: "TP",
      valueFormatter: params => currencyFormatter(params.value, "$"),
      headerTooltip: "Total Payments with paid date within the date range",
      tooltipValueGetter: () =>
        "Total Payments with paid date within the date range",
    },
    {
      field: "tp_roi",
      headerName: "TP ROI",
      valueGetter: params => adservRatio(params.data.tp, params.data.call_cost)
    },
    {
      field: "ip_loan",
      headerName: "IP Loan",
      valueFormatter: params => currencyFormatter(params.value, "$"),
      headerTooltip: "Initial Loan Payments with paid date within the date range",
    },
    {
      field: "ip_loan_percent",
      headerName: "IP Loan %",
      valueGetter: params => percentageOf(params.data.ip_loan, params.data.ip),
      valueFormatter: params => percentageFormatter(params.value),
      headerTooltip: "Percent of IP that is from a loan",
    }
  ];

  // Preserving Filter Model
  const [filterModel, setFilterModel] = useState(null);  // Save current filters

  // Loading state to track when data is being fetched
  const [loading, setLoading] = useState(true);

  // Fetch data based on date range
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Set loading to true before fetching
      if (gridRef.current) {
        // Save current filter state
        const currentFilterModel = gridRef.current.api.getFilterModel();
        setFilterModel(currentFilterModel);
      }

      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      };
      const reqUrl = `${process.env.REACT_APP_API_URL}/api/reports?report=${props.reportName}&startDate=${props.startDate}&endDate=${props.endDate}`;

      try {
        const result = await fetch(reqUrl, requestOptions);
        const gridData = await result.json();
        setRowData(gridData.data);
      } catch (error) {
        console.error("ERROR: " + error);
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    };

    fetchData();
  }, [props.reportName, props.startDate, props.endDate]);

  // After new data is set, reapply the filter model
  useEffect(() => {
    if (gridRef.current && filterModel) {
      gridRef.current.api.setFilterModel(filterModel);
    }
  }, [rowData]);

  // If rows have not yet been returned
  // if (loading) {
  //   return (
  //     <div>
  //       <h1 className='color2-font'>DATA LOADING</h1>
  //     </div>
  //   )
  // }
  

  return (
    // wrapping container with theme & size
    <div
      className="ag-theme-quartz h-60 m-top-3 greyBg" // applying the grid theme
    // style={{ overflow: 'hidden' }} // the grid will fill the size of the parent container
    >
      <div id="summaryBox">
        <p id="summaryText">
          <strong>Splits Entity Overview</strong><br />
          This view will show data for the cases created or sold within the filtered date range.
          <br />
          <ul>
            <li>Payment totals are filtered via paid date and are <strong>independent</strong> of the sale date of the case.</li>
            <li>Splits are taken into consideration to provide a more accurate credit to each Settlement Officer.</li>
          </ul>
        </p>
      </div>
      <div style={{ position: 'relative', height: '100%' }}> {/* New wrapper for AG Grid */}
        <AgGridReact
          enableCharts={true}
          enableRangeSelection={true}
          gridRef={gridRef}
          rowData={rowData}
          columnDefs={colDefs}
          getGroupRowAgg={getGroupRowAgg}
          gridOptions={gridOptions}
          defaultColDef={defaultColDef}
          // groupIncludeTotalFooter={groupIncludeTotalFooter}
          modules={[ServerSideRowModelModule, RowGroupingModule]}
        />
        {loading && (
            <div className="loading-overlay">
            <div className="spinner"></div>
            </div>
        )}
      </div>
    </div>
  )
}