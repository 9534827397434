// Helpers to export to the calculator component 
module.exports = {
  leadCountUpdate: (rowData) => {
    /* 
      When the lead count changes the following must occur
      1. Calculate the weekly spend based on the lead count and cost per call
      2. Calculate the Deal Count based on the weekly spend and CPA
      3. Calculate the IP based on the deal count and average IP
      4. Calculate the IP ROI based on the IP and weekly spend
    */
    // console.log("rowData: ", rowData);
    let vendorSpend = Math.ceil(rowData.lead_count * rowData.cpc, 2);
    let dealCount = rowData.cpa && vendorSpend ? Math.floor(vendorSpend / rowData.cpa, 2) : 0;
    let ip = Math.round(dealCount * rowData.avg_ip, 2);
    let ipRoi = parseFloat((ip / vendorSpend).toFixed(2));

    rowData.vendor_spend = vendorSpend;
    rowData.deal_count = dealCount;
    rowData.initial = ip;
    rowData.ip_roi = ipRoi;

    return rowData;
  },
  weeklySpendUpdate: (rowData) => {
    console.log("rowData: ", rowData);
    /*
      When the Weekly Spend changes the following must occur
      1. Calculate the lead count based on the weekly spend and cost per call
      2. Calulate the deal count based on the weekly spend and CPA
      3. Calculate the IP based on the deal count and average IP
      4. Calculate the IP ROI based on the IP and weekly spend
    */
    let leadCount = Math.floor(rowData.vendor_spend / rowData.cpc, 2);
    let dealCount = rowData.cpa && rowData.vendor_spend ? Math.floor(rowData.vendor_spend / rowData.cpa, 2) : 0;
    let ip = Math.round(dealCount * rowData.avg_ip, 2);
    let ipRoi = parseFloat((ip / rowData.vendor_spend).toFixed(2));

    rowData.lead_count = leadCount;
    rowData.deal_count = dealCount;
    rowData.initial = ip;
    rowData.ip_roi = ipRoi;

    return rowData;
  },
  dealCountUpdate: (rowData) => {
    /*
      When the Deal Count changes the following must occur
      1. Calculate the weekly spend based on the deal count and CPA
      2. Calculate the lead count based on the weekly spend and cost per call
      3. Calculate the IP based on the deal count and average IP
      4. Calculate the IP ROI based on the IP and weekly spend
    */
    // First check if CPA is greater than 0 if not there are no deals
    if (rowData.cpa <= 0) {
      rowData.deal_count = 0;
      return rowData;
    }
    let vendorSpend = Math.round(rowData.deal_count * rowData.cpa, 2);
    let leadCount = Math.round(vendorSpend / rowData.cpc, 2);
    let ip = Math.round(rowData.deal_count * rowData.avg_ip, 2);
    let ipRoi = parseFloat((ip / vendorSpend).toFixed(2));

    rowData.vendor_spend = vendorSpend;
    rowData.lead_count = leadCount;
    rowData.initial = ip;
    rowData.ip_roi = ipRoi;

    return rowData;
  },
  cpaUpdate: (rowData) => {
    /*
      When the CPA changes the following must occur
      1. Calculate the deal count based on weekly spend and cpa
      2. Calculate IP based on deal count and average IP
      3. Calculate the IP ROI based on the IP and weekly spend
    */
    let dealCount = Math.floor(rowData.vendor_spend / rowData.cpa, 2);
    let ip = Math.round(dealCount * rowData.avg_ip, 2);
    let ipRoi = parseFloat((ip / rowData.vendor_spend).toFixed(2));

    rowData.deal_count = dealCount;
    rowData.initial = ip;
    rowData.ip_roi = ipRoi;

    return rowData;
  },
  ipUpdate: (rowData) => {
    /*
      When the IP changes the following must occur
      1. Calcualte the deal count based on the IP and average IP, we should overshoot the ip that was entered
        ex. If they enter $50,000 IP and the avg IP is $1,670, then we should set the deal count to 30 an the IP to $50,100
      2. Then caculate the weekly spend based on the deal count and CPA
      3. Calculate the lead count based on the weekly spend and cost per call
      4. Calculate the IP ROI based on the IP and weekly spend
    */
    // First check if the avg ip is greater than 0
    if (rowData.avg_ip <= 0) {
      rowData.initial = 0;
      return rowData;
    }
    let dealCount = Math.ceil(rowData.initial / rowData.avg_ip, 2);
    let ip = dealCount * rowData.avg_ip;
    let vendorSpend = Math.round(dealCount * rowData.cpa, 2);
    let leadCount = Math.round(vendorSpend / rowData.cpc, 2);
    let ipRoi = parseFloat((ip / vendorSpend).toFixed(2));

    rowData.deal_count = dealCount;
    rowData.initial = ip;
    rowData.vendor_spend = vendorSpend;
    rowData.lead_count = leadCount;
    rowData.ip_roi = ipRoi;

    return rowData;
  },
  avgIpUpdate: (rowData) => {
    /*
      When the Avg IP changes the following must occur
      1. Calculate IP based on n the deal count and average IP
      2. Calculate the IP ROI based on the IP and weekly spend
    */
    let ip = Math.round(rowData.deal_count * rowData.avg_ip, 2);
    let ipRoi = parseFloat((ip / rowData.vendor_spend).toFixed(2));
    rowData.initial = ip;
    rowData.ip_roi = ipRoi;

    return rowData;
  },
  begginingOfWeek: () => {
    // Return the date from the beggining of the current week in PST
    let date = new Date();
    let day = date.getDay();
    let diff = date.getDate() - day + (day === 0 ? -6 : 1);
    let monday = new Date(date.setDate(diff));
    let mondayDate = monday.toLocaleDateString('en-US', { timeZone: 'America/Los_Angeles' });
    return mondayDate;
  },
  currentDay: () => {
    // Return the date from the current day in PST
    let date = new Date();
    let today = date.toLocaleDateString('en-US', { timeZone: 'America/Los_Angeles' });
    return today;
  },
  begginingOfLastWeek: () => {
    // Return the date from the beggining of the last week in PST
    let date = new Date();
    let day = date.getDay();
    let diff = date.getDate() - day + (day === 0 ? -6 : 1);
    diff = diff - 7;
    let monday = new Date(date.setDate(diff));
    let mondayDate = monday.toLocaleDateString('en-US', { timeZone: 'America/Los_Angeles' });
    return mondayDate;
  },
  endOfLastWeek: () => {
    // Return saturday of the last week in PST
    let date = new Date();
    let day = date.getDay();
    let diff = date.getDate() - day + (day === 0 ? -6 : 1);
    diff = diff - 2;
    let saturday = new Date(date.setDate(diff));
    let saturdayDate = saturday.toLocaleDateString('en-US', { timeZone: 'America/Los_Angeles' });
    return saturdayDate;
  },
  begginingOfMonth: () => {
    // Return the date from the beggining of the current month in PST
    let date = new Date();
    let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    let firstDayDate = firstDay.toLocaleDateString('en-US', { timeZone: 'America/Los_Angeles' });
    return firstDayDate;
  },
  begginingOfLastMonth: () => {
    // Return the date from the beggining of the last month in PST
    let date = new Date();
    let firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1);
    let firstDayDate = firstDay.toLocaleDateString('en-US', { timeZone: 'America/Los_Angeles' });
    return firstDayDate;
  },
  endOfLastMonth: () => {
    // Return the date from the end of the last month in PST
    let date = new Date();
    let lastDay = new Date(date.getFullYear(), date.getMonth(), 0);
    let lastDayDate = lastDay.toLocaleDateString('en-US', { timeZone: 'America/Los_Angeles' });
    return lastDayDate;
  },
  begginingOfQuarter: () => {
    // Return the date from the beggining of the current quarter in PST
    let date = new Date();
    let quarter = Math.floor((date.getMonth() + 3) / 3);
    let firstDay = new Date(date.getFullYear(), (quarter - 1) * 3, 1);
    let firstDayDate = firstDay.toLocaleDateString('en-US', { timeZone: 'America/Los_Angeles' });
    return firstDayDate;
  },
  begginingOfYear: () => {
    // Return the date from the beggining of the current year in PST
    let date = new Date();
    let firstDay = new Date(date.getFullYear(), 0, 1);
    let firstDayDate = firstDay.toLocaleDateString('en-US', { timeZone: 'America/Los_Angeles' });
    return firstDayDate;
  }
}