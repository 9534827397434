import React, { useEffect, useState } from 'react';
import './App.css';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { initializeApp } from 'firebase/app';


import Login from './components/login/Login';
import Dashboard from './components/dashboard/Dashboard';
import Signup from './components/signup/Signup';
import ReportQuery from './components/reportQuery/ReportQuery';
import Calculator from './components/calculator/Calculator';

const managersListUrl = "https://sheets.googleapis.com/v4/spreadsheets/128QYnOHCGvMCxICVAd1q6d1BCD0m2PWtFMD1RvGmIhE/values/Sheet1!A2:A?key=AIzaSyBAonE18rEx_PN_gAJdi0Dt0_c-4F0RwgY"
const adminListUrl = "https://sheets.googleapis.com/v4/spreadsheets/128QYnOHCGvMCxICVAd1q6d1BCD0m2PWtFMD1RvGmIhE/values/Sheet1!B2:B?key=AIzaSyBAonE18rEx_PN_gAJdi0Dt0_c-4F0RwgY"
const marketingUrl = "https://sheets.googleapis.com/v4/spreadsheets/128QYnOHCGvMCxICVAd1q6d1BCD0m2PWtFMD1RvGmIhE/values/Sheet1!C2:C?key=AIzaSyBAonE18rEx_PN_gAJdi0Dt0_c-4F0RwgY"

// Firebase project configuration
const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_ID,
    appId: process.env.REACT_APP_APP_ID,
};

const app = initializeApp(firebaseConfig);
// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);

export function App() {
    const [managerList, setManagerList] = useState([]);
    const [adminList, setadminList] = useState([]);
    const [marketingList, setMarketingList] = useState([]);
    const [loggedInUser, setLoggedInUser] = useState({});

    // Function to pull the emails out of the 
    const extractEmails = (data) => {
        // console.log("DATA: ", data);
        let emailList = [];
        const vals = data.values;
        for (let i = 0; i < vals.length; i++) {
            emailList.push(vals[i][0]);
        }
        return emailList;
    };

    // Manager Email Fetch
    useEffect(() => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        };
        fetch(managersListUrl, requestOptions)
            .then(result => result.json())
            .then(data => {
                let emailList = extractEmails(data);
                setManagerList(emailList);
            })
            .catch(err => console.log("ERROR: " + err))
    }, []);

    // Admin Email Fetch
    useEffect(() => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        };
        fetch(adminListUrl, requestOptions)
            .then(result => result.json())
            .then(data => {
                let emailList = extractEmails(data);
                setadminList(emailList);
            })
            .catch(err => console.log("ERROR: " + err))
    }, []);

    // Marketing Email Fetch
    useEffect(() => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        };
        fetch(marketingUrl, requestOptions)
            .then(result => result.json())
            .then(data => {
                let emailList = extractEmails(data);
                setMarketingList(emailList);
            })
            .catch(err => console.log("ERROR: " + err))
    }, []);

    // Check logged in state
    onAuthStateChanged(auth, (user) => {
        // console.log("user: ", user);
        // Set the user to the state
        setLoggedInUser(user);
    });

    return (
        <BrowserRouter>
            <Routes>
                <Route exact path="/" element={<Login />} />
                <Route exact path="/dashboard" element={<Dashboard managerList={managerList} adminList={adminList} marketingList={marketingList} />} />
                <Route exact path="/signup" element={<Signup />} />
                <Route exact path="/reportPicker" element={<ReportQuery managerList={managerList} adminList={adminList} loggedInUser={loggedInUser} />} />
                <Route exact path="/calculator" element={<Calculator />} />
            </Routes>
        </BrowserRouter>
    );
}
