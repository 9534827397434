
import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import trhLogo from '../static/trhLogo.png';
import SplitsEntityOverview from '../splitsEntityOverview/SplitsEntityOverview';
import MarketingEntityOverview from '../marketingEntityOverview/MarketingEntityOverview';
import CashFlow from '../cashFlow/CashFlow';
import LeadsByMarketingLine from '../leadsByMarketingLine/LeadsByMarketingLine';
import LostMoney from '../lostMoney/LostMoney';
import LostMoneyBreakdown from '../lostMoneyBreakdown/LostMoneyBreakdown';
import ProductivityReport from '../productivityReport/ProductivityReport';
import OpenerReport from '../openerReport/OpenerReport';
import DispoReport from '../dispoReport/DispoReport';
import DialerReport from '../dialerReport/DialerReport';
import LogicsActivity from '../logicsActivity/LogicsActivity';
import LienReport from '../lienReport/LienReport';

import './ReportQuery.css';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { initializeApp } from 'firebase/app';


const yesterdaysDate = () => {
  const today = new Date();
  const losAngelesTime = new Date(today.toLocaleString('en-US', { timeZone: 'America/Los_Angeles' }));
  let yesterday = new Date(losAngelesTime);
  yesterday.setDate(losAngelesTime.getDate() - 1);
  yesterday.setHours(today.getHours() - losAngelesTime.getHours());
  const formattedYesterday = yesterday.toISOString().split('T')[0];
  return formattedYesterday;
};

const isDateValid = (inputDate) => {
  const today = new Date();
  today.setDate(today.getDate());
  const input = new Date(inputDate);
  return input <= today;
};

// Firebase project configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_ID,
  appId: process.env.REACT_APP_APP_ID,
};

const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);
export function ReportQuery({ managerList, adminList, loggedInUser }) {
  const [reportName, setReportName] = useState('');
  const [startDate, setStartDate] = useState(yesterdaysDate());
  const [endDate, setEndDate] = useState(yesterdaysDate());
  const [reportComp, setReportComp] = useState(null);
  const [, setLoggedInUser] = useState({});
  let [loggedIn, setLoggedIn] = useState(true);

  const updateReport = (e) => {
    setReportName(e.target.value);
    console.log("e.target.value: " + e.target.value)
  };

  const updateStartDate = (e) => {
    const enteredDate = e.target.value;
    // const updateval = isDateValid(enteredDate) ? enteredDate : yesterdaysDate();
    setStartDate(enteredDate);
  };

  const updateEndDate = (e) => {
    const enteredDate = e.target.value;
    // const updateval = isDateValid(enteredDate) ? enteredDate : yesterdaysDate();
    setEndDate(enteredDate);
  };

  const displayReport = (e) => {
    let rc = null;
    if (startDate !== '' && endDate !== '') {
      if (reportName === 'splitsEntityOverview') {
        rc = <SplitsEntityOverview startDate={startDate} endDate={endDate} reportName={'splitsEntityOverview'} />;
      } else if (reportName === 'cashFlow') {
        rc = <CashFlow startDate={startDate} endDate={endDate} reportName={'cashFlow'} />;
      } else if (reportName === 'leadsByMarketingLine') {
        rc = <LeadsByMarketingLine startDate={startDate} endDate={endDate} reportName={'leadsByMarketingLine'} />;
      } else if (reportName === 'lostMoney') {
        rc = <LostMoney startDate={startDate} endDate={endDate} reportName={'lostMoney'} />;
      } else if (reportName === 'lostMoneyBreakdown') {
        rc = <LostMoneyBreakdown startDate={startDate} endDate={endDate} reportName={'lostMoneyBreakdown'} />;
      } else if (reportName === 'productivityReport') {
        rc = <ProductivityReport startDate={startDate} endDate={endDate} reportName={'productivityReport'} />;
      } else if (reportName === 'openerReport') {
        rc = <OpenerReport startDate={startDate} endDate={endDate} reportName={'openerReport'} />;
      } else if (reportName === 'marketingEntityOverview') {
        rc = <MarketingEntityOverview startDate={startDate} endDate={endDate} reportName={'marketingEntityOverview'} />;
      } else if (reportName === 'dispositionReport') {
        rc = <DispoReport startDate={startDate} endDate={endDate} reportName={'dispositionReport'} />;
      } else if (reportName === 'dialerReport') {
        rc = <DialerReport startDate={startDate} endDate={endDate} reportName={'dialerReport'} />;
      } else if (reportName === 'logicsActivity') {
        rc = <LogicsActivity startDate={startDate} endDate={endDate} reportName={'logicsActivity'} />;
      } else if (reportName === 'lienReport') {
        rc = <LienReport startDate={startDate} endDate={endDate} reportName={'lienReport'} />;
      }
    }
    setReportComp(rc);
  };

  const managerReports = {
    splitsEntityOverview: 'Splits Entity Overview',
    productivityReport: 'Productivity Report',
    openerReport: 'Opener Report',
    marketingEntityOverview: 'Marketing Entity Overview',
    dispositionReport: 'Disposition Report',
    dialerReport: "Dialer Report",
    logicsActivity: 'Logics Activity'
  };

  const adminReports = {
    cashFlow: 'Cash Flow',
    leadsByMarketingLine: 'Leads By Marketing Line',
    lostMoney: 'Lost Money',
    lostMoneyBreakdown: 'Lost Money Breakdown',
    lienReport: 'Lien Report',
  };

  // If the user is a manager, they will get the manager report, if they are admin we combine both lists here
  const reportOptionsMap = managerList && loggedInUser && loggedInUser.email && managerList.includes(loggedInUser.email.toLowerCase())
    ? managerReports
    : adminList && loggedInUser && loggedInUser.email && adminList.includes(loggedInUser.email.toLowerCase())
    ? { ...managerReports, ...adminReports }
    : {};

  // Sort the reports
  const sortedOptions = Object.entries(reportOptionsMap).sort()
  const optionElements = sortedOptions.map(([key, value]) => (
    <option key={key} value={key}>
      {value}
    </option>
  ));

  // Check logged in state
  onAuthStateChanged(auth, (user) => {
    // console.log("user: ", user);
    // Set the user to the state
    setLoggedInUser(user);
    if (user && user.emailVerified) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/auth.user
      // console.log("user.email: ", user.email);
    } else {
      console.log("NOT LOGGED IN/VERIFIED");
      setLoggedIn(false);
    }
  });

  const navigate = useNavigate();
  if(!loggedIn)  {
    return navigate("/");
  }
  return (
    <div className=''>
      <div className='topBar'>
        <div>
          <img src={trhLogo} alt="Logo" height={50} />
          <Link to='/dashboard' className='m-left-2'>
            Dashboard
          </Link>
        </div>
        <div className='f justify-center'>
          <div>
            <label>Report Name: </label>
            <select
              name="reportName"
              className='large-font'
              onChange={(e) => updateReport(e)}
            >
              <option value={""}></option>
              {optionElements}
            </select>
          </div>
          <div className='m-left-2'>
            <label>Start Date: </label>
            <input
              name='startDate'
              className='large-font'
              id='startDate'
              type='date'
              defaultValue={yesterdaysDate()}
              onChange={(e) => updateStartDate(e)}
            />
          </div>
          <div className='m-left-2'>
            <label>End Date: </label>
            <input
              name='endDate'
              className='large-font'
              id='endDate'
              type='date'
              defaultValue={yesterdaysDate()}
              onChange={(e) => updateEndDate(e)}
            />
          </div>
        </div>
        <div className='center-text m-top-3'>
          <button className='blue-butt' onClick={(e) => displayReport(e)}>Fetch Report</button>
        </div>
      </div>
      <div id="reportBox">{reportComp}</div>
    </div>
  );
};
export default ReportQuery;
