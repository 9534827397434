// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body.active-modal {
    overflow-y: hidden;
}

.btn-modal {
    padding: 10px 20px;
    display: block;
    margin: 100px auto 0;
    font-size: 18px;
}

.modal, .overlay {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
}

.overlay {
    background: rgba(49,49,49,0.8);
}
.modal-content {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #f1f1f1;
    padding: 14px 28px 28px 28px;
    border-radius: 3px;
    max-width: 600px;
    min-width: 300px;
}

.close-modal {
    position: absolute;
    top: 15px;
    right: 20px;
    padding: 10px 16px;
    /* padding: 5px 7px; */
    /* width: 27px; */
    /* height: 27px; */
    font-size: 20px;
}

#blockNumberInput {
    height: 35px;
    padding: 5px;
    font-size: 20px;
}

.add-btn {
    position: absolute;
    /* top: 10px; */
    /* bottom: 15px; */
    right: 20px;
    padding: 10px 40px;
    /* width: 70px; */
    /* height: 100px; */
    font-size: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/components/modal/Modal.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,cAAc;IACd,oBAAoB;IACpB,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;IACT,eAAe;AACnB;;AAEA;IACI,8BAA8B;AAClC;AACA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,gBAAgB;IAChB,mBAAmB;IACnB,4BAA4B;IAC5B,kBAAkB;IAClB,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,kBAAkB;IAClB,sBAAsB;IACtB,iBAAiB;IACjB,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,eAAe;IACf,kBAAkB;IAClB,WAAW;IACX,kBAAkB;IAClB,iBAAiB;IACjB,mBAAmB;IACnB,eAAe;AACnB","sourcesContent":["body.active-modal {\n    overflow-y: hidden;\n}\n\n.btn-modal {\n    padding: 10px 20px;\n    display: block;\n    margin: 100px auto 0;\n    font-size: 18px;\n}\n\n.modal, .overlay {\n    width: 100vw;\n    height: 100vh;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    position: fixed;\n}\n\n.overlay {\n    background: rgba(49,49,49,0.8);\n}\n.modal-content {\n    position: absolute;\n    top: 40%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    line-height: 1.4;\n    background: #f1f1f1;\n    padding: 14px 28px 28px 28px;\n    border-radius: 3px;\n    max-width: 600px;\n    min-width: 300px;\n}\n\n.close-modal {\n    position: absolute;\n    top: 15px;\n    right: 20px;\n    padding: 10px 16px;\n    /* padding: 5px 7px; */\n    /* width: 27px; */\n    /* height: 27px; */\n    font-size: 20px;\n}\n\n#blockNumberInput {\n    height: 35px;\n    padding: 5px;\n    font-size: 20px;\n}\n\n.add-btn {\n    position: absolute;\n    /* top: 10px; */\n    /* bottom: 15px; */\n    right: 20px;\n    padding: 10px 40px;\n    /* width: 70px; */\n    /* height: 100px; */\n    font-size: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
