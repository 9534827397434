import React, { useState, useEffect, useRef } from 'react';

import { AgGridReact } from 'ag-grid-react'; // AG Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import "ag-grid-enterprise";
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';

import { currencyFormatter, dateFormatter } from '../valueformatter';

// Splits Breakdown component
export default function SplitsBreakdown(props) {
  const gridRef = useRef();
  // Row Data: the data to be displayed
  const [rowData, setRowData] = useState([]);
  // Grid Options
  const gridOptions = {
    sideBar: true,
    rowGroupPanelShow: 'always',
    columnHoverHighlight: true,
    groupIncludeTotalFooter: true,
    suppressAggFuncInHeader: true,
    autoGroupColumnDef: {
      cellRendererParams: {
        footerValueGetter: params => {
          const isRootLevel = params.node.level === -1;
          if (isRootLevel) {
            return 'Grand Total';
          }
          return `Sub Total (${params.value})`;
        },
      },
      headerName: 'Team',
    },
    getRowStyle: params => {
      // Grand Total row formatting
      if (params.node.group === true && params.node.level === -1) {
        return { background: "#3F91B8", color: 'white', fontWeight: 'bold' };
      } else if (params.node.group === true) {
        return { background: "#b5c4ca", fontWeight: 'bold' };
      }
    },
    autoSizeStrategy: {
      type: 'fitCellContents'
    },
  };

  // Default column definitions 
  const defaultColDef = {
    filter: true,
    enableRowGroup: true,
  }

  const colDefs = [
    {
      field: "company", headerName: "Team", enableRowGroup: true, rowGroup: true, sort: "asc", hide: true,
    },
    {
      field: "settlement_officer", headerName: "Sales Rep",
      sort: "asc", rowGroup: true, hide: true,
    },
    {
      field: "method", headerName: "Method",
      rowGroup: true, hide: true,
    },
    {
      field: "case_number", headerName: "Case #",
    },
    {
      field: "payment_date", headerName: "Payment Date",
      valueFormatter: params => dateFormatter(params.value),
    },
    {
      field: "tag"
    },
    {
      field: "paid_amount", headerName: "Amount", cellStyle: { textAlign: 'right' },
      aggFunc: "sum",
      valueFormatter: params => currencyFormatter(params.value, "$"),
    },
  ];

  // Loading state to track when data is being fetched
  const [loading, setLoading] = useState(false);

  // Fetch data based on date range
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setRowData([]);
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      };
      const reqUrl = `${process.env.REACT_APP_API_URL}/api/reports?report=${props.reportName}&startDate=${props.startDate}&endDate=${props.endDate}`;

      try {
        const result = await fetch(reqUrl, requestOptions);
        const gridData = await result.json();
        setRowData(gridData.data);
      } catch (error) {
        console.error("ERROR: " + error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [props.reportName, props.startDate, props.endDate]);

  // Autosize the grouped column when the grid is first rendered
  const onFirstDataRendered = (params) => {
    // Filter calls
    filteredUnusedEntities();
    setTimeout(() => {
      params.columnApi.autoSizeAllColumns();
    }, 100);
  };

  const filteredUnusedEntities = () => {
    gridRef.current.api.getFilterInstance('company', function(filter) {
      filter.setModel({
          values: filter.getValues().filter(value => !['Dallas', 'Las Vegas'].includes(value))
      });

      // Trigger filter update
      gridRef.current.api.onFilterChanged();
    });
  };

  // Autosizes all columns when row group is expanded
  const onRowGroupOpened = (params) => {
    setTimeout(() => {
      params.columnApi.autoSizeAllColumns();
    }, 100);
  };

  return (
    <div
      className="ag-theme-quartz h-75 greyBg"
    >
      <div id="summaryBox">
        <p id="summaryText">
          <strong>Splits Breakdown</strong><br />
          This view will total revenue by method for each sales rep, taking into consideration splits on each payment.
        </p>
      </div>
      <div style={{position: 'relative', height: '100%'}}>
        <AgGridReact 
          onFirstDataRendered={onFirstDataRendered}
          onRowGroupOpened={onRowGroupOpened}
          enableCharts={true}
          enableRangeSelection={true}
          ref={gridRef}
          rowData={rowData}
          columnDefs={colDefs}
          gridOptions={gridOptions}
          defaultColDef={defaultColDef}
          modules={[ServerSideRowModelModule, RowGroupingModule]}
          defaultSideBar={false}
          sideBar={{
            toolPanels: [
              {
                id: 'columns',
                labelDefault: 'Columns',
                labelKey: 'columns',
                iconKey: 'columns',
                toolPanel: 'agColumnsToolPanel',
              },
              {
                id: 'filters',
                labelDefault: 'Filters',
                labelKey: 'filters',
                iconKey: 'filter',
                toolPanel: 'agFiltersToolPanel',
              }
            ]
          }}
        />
        {loading && (
          <div className="loading-overlay">
            <div className="spinner"></div>
          </div>
        )}
      </div>
    </div>
  )
}