import React, { useState, useEffect, useRef, useCallback } from 'react';

import { AgGridReact } from 'ag-grid-react'; // AG Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import "ag-grid-enterprise";
import "ag-grid-charts-enterprise";

import CCCallData from '../ccCallData/CCCallData';
import CCDispositionData from '../ccDispositionData/CCDispositionData';

export default function CCDashboard(props) {
    // const gridRef = useRef();
    const [rowData, setRowData] = useState([]);
    const [loading, setLoading] = useState(false);

    // Fetch data based on date range
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true); // Set loading to true before fetching
            

            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            };
            const reqUrl = `${process.env.REACT_APP_API_URL}/api/reports?report=${props.reportName}&startDate=${props.startDate}&endDate=${props.endDate}`;

            try {
                const result = await fetch(reqUrl, requestOptions);
                const gridData = await result.json();
                setRowData(gridData.data);
            } catch (error) {
                console.error("ERROR: " + error);
            } finally {
                setLoading(false); // Set loading to false after fetching
            }
        };

        fetchData();
    }, [props.reportName, props.startDate, props.endDate]);


    return (
        // wrapping container with theme & size 
        <div
            className='ag-theme-quartz greyBg' // applying the grid theme
        >
            <div style={{ position: 'relative', height: '100%', }}> {/* New wrapper for AG Grid */}
                <CCCallData
                    rowData={rowData}
                    setRowData={setRowData}
                    loading={loading}
                />
            </div>
        </div>
    )
};
