import React, { useState, useRef, useEffect, useMemo } from 'react';

import { AgGridReact } from 'ag-grid-react'; // AG Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import './ProductivityReport.css'
import "ag-grid-enterprise";
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';

import { dateFormatter, convertToHours } from '../valueformatter';

export default function ProductivityReport(props) {
  const gridRef = useRef(); // Optional - for accessing Grid's API
  const [gridOptions] = useState({
    sideBar: true,
    rowGroupPanelShow: 'always',
    groupDefaultExpanded: 1,
    columnHoverHighlight: true,
    // groupIncludeFooter: true,
    groupIncludeTotalFooter: true,
    suppressAggFuncInHeader: true,
    autoGroupColumnDef: {
      cellRendererParams: {
        footerValueGetter: params => {
          const isRootLevel = params.node.level === -1;
          // console.log("isRootLevel: ", isRootLevel);
          if (isRootLevel) {
            return 'Grand Total';
          }
          return `Sub Total (${params.value})`;
        },
      },
      headerName: 'Entity',
    },
    getRowStyle: params => {
      // Grand Total row formatting
      if (params.node.group === true && params.node.level === -1) {
        return { background: '#3F91B8', color: 'white', fontWeight: 'bold' };
        // #b5c4ca
        // #99bac9
      }
      // Subtotal row formatting
      else if (params.node.group === true) {
        return { background: '#b5c4ca', fontWeight: 'bold' };
      }
    },
    autoSizeStrategy: {
      type: 'fitCellContents'
    },
  });

  

  // DefaultColDef sets props common to all Columns
  const defaultColDef = useMemo(() => ({
    filter: true,
  }), []);


  console.log("props: ", props);
  /*
    Row Object Example
    {
      "agent":"Roger Ruiz",
      "company":"Woodland Hills",
      "date":"2024-03-26",
      "calls":48,
      "available":120,
      "inbound_contact":40,
      "outbound_consult":50,
      "outbound_contact":50,
      "unavailable":90,
      "unavailable_break":400,
      "unavailable_consult_pending":24,
      "unavailable_inbound_pending":92,
      "unavailable_on_another_call":128,
      "unavailable_outbound_pending":257,
      "unavailable_refused":390,
      "unavailable_wrap_up":400,
    }
  */

  // Column Definitions: Defines the columns to be displayed.
  const [colDefs] = useState([
    { field: "company", headerName: "Logics", sort: "asc", enableRowGroup: true, rowGroup: true, hide: true },
    { field: "agent", headerName: "Agent", sort: "asc", enableRowGroup: true, rowGroup: true, },
    {
      field: "date", headerName: "Date", sort: "asc", enableRowGroup: true,
      valueFormatter: params => dateFormatter(params.value),
      // valueGetter: params => dateFormatter(params.value),
    },
    {
      field: "login_time", headerName: "Login Time", enableRowGroup: true,
      valueFormatter: params => {
				if (!params.node.group && params.value) {
					return new Date(params.value).toLocaleString('en-US', { 
						timeZone: 'America/Los_Angeles',
						timeZoneName: 'short', // Shows either PST or PDT
            month: '2-digit',
						day: '2-digit',
						year: 'numeric',
						hour: '2-digit',
						minute: '2-digit',
						second: '2-digit',
						hour12: true
					})
				} else {
					return params.value;
				}
				
      },
    },
    {
      field: "outbound_calls", headerName: "Outbounds", cellStyle: { textAlign: 'right' }, aggFunc: 'sum',
    },
    {
      field: "available", headerName: "Available", cellStyle: { textAlign: 'right' }, aggFunc: 'sum',
      valueFormatter: params => convertToHours(params.value),
    },
    {
      field: "inbound_contact", headerName: "Inbound", cellStyle: { textAlign: 'right' }, aggFunc: 'sum',
      valueFormatter: params => convertToHours(params.value),
    },
    {
      field: "outbound_consult", headerName: "Outbound Consult", cellStyle: { textAlign: 'right' }, aggFunc: 'sum',
      valueFormatter: params => convertToHours(params.value),
    },
    {
      field: "outbound_contact", headerName: "Outbound Contact", cellStyle: { textAlign: 'right' }, aggFunc: 'sum',
      valueFormatter: params => convertToHours(params.value),
    },
    {
      field: "unavailable", headerName: "Unavailable", cellStyle: { textAlign: 'right' }, aggFunc: 'sum',
      valueFormatter: params => convertToHours(params.value),
    },
    {
      field: "unavailable_break", headerName: "Unavailable Break", cellStyle: { textAlign: 'right' }, aggFunc: 'sum',
      valueFormatter: params => convertToHours(params.value),
    },
    {
      field: "unavailable_consult_pending", headerName: "Unavailable Consult Pending", cellStyle: { textAlign: 'right' }, aggFunc: 'sum',
      valueFormatter: params => convertToHours(params.value),
    },
    {
      field: "unavailable_inbound_pending", headerName: "Unavailable Inbound Pending", cellStyle: { textAlign: 'right' }, aggFunc: 'sum',
      valueFormatter: params => convertToHours(params.value),
    },
    {
      field: "unavailable_on_another_call", headerName: "Unavailable On Another Call", cellStyle: { textAlign: 'right' }, aggFunc: 'sum',
      valueFormatter: params => convertToHours(params.value),
    },
    {
      field: "unavailable_outbound_pending", headerName: "Unavailable Outbound Pending", cellStyle: { textAlign: 'right' }, aggFunc: 'sum',
      valueFormatter: params => convertToHours(params.value),
    },
    {
      field: "unavailable_refused", headerName: "Unavailable Refused", cellStyle: { textAlign: 'right' }, aggFunc: 'sum',
      valueFormatter: params => convertToHours(params.value),
    },
    {
      field: "unavailable_wrap_up", headerName: "Unavailable Wrap Up", cellStyle: { textAlign: 'right' }, aggFunc: 'sum',
      valueFormatter: params => convertToHours(params.value),
    },
  ]);

  // Row Data: The data to be displayed.
  const [rowData, setRowData] = useState([]);

  // Preserving Filter Model
  const [filterModel, setFilterModel] = useState(null);  // Save current filters

  // Loading state to track when data is being fetched
  const [loading, setLoading] = useState(true);

  // Fetch data based on date range
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Set loading to true before fetching
      // if (gridRef.current) {
      //   // Save current filter state
      //   const currentFilterModel = gridRef.current.api.getFilterModel();
      //   setFilterModel(currentFilterModel);
      // }

      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      };
      const reqUrl = `${process.env.REACT_APP_API_URL}/api/reports?report=${props.reportName}&startDate=${props.startDate}&endDate=${props.endDate}`;

      try {
        const result = await fetch(reqUrl, requestOptions);
        const gridData = await result.json();
        setRowData(gridData.data);
      } catch (error) {
        console.error("ERROR: " + error);
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    };

    fetchData();
  }, [props.reportName, props.startDate, props.endDate]);

  // // After new data is set, reapply the filter model
  // useEffect(() => {
  //   if (gridRef.current && filterModel) {
  //     gridRef.current.api.setFilterModel(filterModel);
  //   }
  // }, [rowData]);

  
  // Autosize the grouped column when the grid is first rendered
  const onFirstDataRendered = (params) => {
    // Filter calls
    filteredUnusedEntities();
    setTimeout(() => {
        params.columnApi.autoSizeAllColumns();
    },100);
  };

  // Autosizes all columns when row group is expanded
  const onRowGroupOpened = (params) => {
      setTimeout(() => {
          params.columnApi.autoSizeAllColumns();
      },100);
  };

  // NOTE: TOOK FOREVER TO FIGURE THIS OUT but I figured it out...basically I was trying to test the filter 
	// for agent_name is equal to Dave Warren but it was failing to apply b/c it was a 'set' type of filter, not a 'text'.
	// // The default filterType for the columns is 'set' since when you click on the filter icon,
	// it shows a set of items to select from. You can change it to filterType 'text', 'number' or 'date' 
	// but you need to update the column definition with the corresponding property:
	// 	'filter: "agTextColumnFilter",
	// 	'filter: "agNumberColumnFilter",
	// 	'filter: "agDateColumnFilter",
  // To filter out something from a set, we need to use a callback function to create a custom filter instance
  const filteredUnusedEntities = () => {
    gridRef.current.api.getFilterInstance('company', function(filter) {
      filter.setModel({
          values: filter.getValues().filter(value => !['Dallas', 'Las Vegas'].includes(value))
      });

      // Trigger filter update
      gridRef.current.api.onFilterChanged();
    });
 };


  return (
    // wrapping container with theme & size
    <div
      className="ag-theme-quartz h-75 greyBg" // applying the grid theme
    // style={{ height: 100% }} // the grid will fill the size of the parent container
    >
      <div id="summaryBox">
        <p id="summaryText">
          <strong>Productivity Report</strong><br />
          This view is a dashboard which has the Outbound Report and the Agent State Duration Report. For  the Agent State Duration Report, a 1 indicates 1 hour.

        </p>
      </div>
      <div style={{ position: 'relative', height: '100%' }}> {/* New wrapper for AG Grid */}
        <AgGridReact
          onFirstDataRendered={onFirstDataRendered}
          onRowGroupOpened={onRowGroupOpened}
          enableCharts={true}
          enableRangeSelection={true}
          ref={gridRef}
          rowData={rowData}
          columnDefs={colDefs}
          gridOptions={gridOptions}
          defaultColDef={defaultColDef}
          modules={[ServerSideRowModelModule, RowGroupingModule]}
          defaultSideBar={false}
          sideBar={{
            toolPanels: [
              {
                id: 'columns',
                labelDefault: 'Columns',
                labelKey: 'columns',
                iconKey: 'columns',
                toolPanel: 'agColumnsToolPanel',
              },
              {
                id: 'filters',
                labelDefault: 'Filters',
                labelKey: 'filters',
                iconKey: 'filter',
                toolPanel: 'agFiltersToolPanel',
              }
            ]
          }}
        />
        {loading && (
          <div className="loading-overlay">
            <div className="spinner"></div>
          </div>
        )}
      </div>
    </div>
  )
}