// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Ensure the overlay covers the full grid area */
.loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7); /* Grey overlay with transparency */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    border-radius: 8px;
  }
  
  .spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .ag-theme-quartz.loading {
    pointer-events: none; /* Disable interactions while loading */
    opacity: 0.6; /* Dim the grid while loading */
    position: relative;
    height: 100%; /* Ensure grid takes up full height */
  }`, "",{"version":3,"sources":["webpack://./src/components/cashFlow/CashFlow.css"],"names":[],"mappings":"AAAA,iDAAiD;AACjD;IACI,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;IACT,WAAW;IACX,YAAY;IACZ,0CAA0C,EAAE,mCAAmC;IAC/E,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;IACX,kBAAkB;EACpB;;EAEA;IACE,oCAAoC;IACpC,6BAA6B;IAC7B,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,kCAAkC;EACpC;;EAEA;IACE,KAAK,uBAAuB,EAAE;IAC9B,OAAO,yBAAyB,EAAE;EACpC;;EAEA;IACE,oBAAoB,EAAE,uCAAuC;IAC7D,YAAY,EAAE,+BAA+B;IAC7C,kBAAkB;IAClB,YAAY,EAAE,qCAAqC;EACrD","sourcesContent":["/* Ensure the overlay covers the full grid area */\n.loading-overlay {\n    position: absolute;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    width: 100%;\n    height: 100%;\n    background-color: rgba(255, 255, 255, 0.7); /* Grey overlay with transparency */\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 10;\n    border-radius: 8px;\n  }\n  \n  .spinner {\n    border: 4px solid rgba(0, 0, 0, 0.1);\n    border-top: 4px solid #3498db;\n    border-radius: 50%;\n    width: 50px;\n    height: 50px;\n    animation: spin 1s linear infinite;\n  }\n  \n  @keyframes spin {\n    0% { transform: rotate(0deg); }\n    100% { transform: rotate(360deg); }\n  }\n  \n  .ag-theme-quartz.loading {\n    pointer-events: none; /* Disable interactions while loading */\n    opacity: 0.6; /* Dim the grid while loading */\n    position: relative;\n    height: 100%; /* Ensure grid takes up full height */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
