import React, {
  useState,
  useRef,
  useEffect
} from "react";
import { AgGridReact } from "ag-grid-react";
import './Calculator.css';
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
// import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
// import { ModuleRegistry } from "@ag-grid-community/core";
// ModuleRegistry.registerModules([ClientSideRowModelModule]);

// Import calculator helpers
import {
  avgIpUpdate, ipUpdate, cpaUpdate, dealCountUpdate,
  leadCountUpdate, weeklySpendUpdate, begginingOfWeek, currentDay,
  begginingOfLastWeek, endOfLastWeek, begginingOfMonth, begginingOfLastMonth,
  endOfLastMonth, begginingOfQuarter, begginingOfYear
} from "./calcHelpers";
import { currencyFormatter } from "../valueformatter";

const test_data = [
  {
    "channel": "RVM",
    "vendor": "RVM-JS-RVM-Trusted",
    "lead_count": 1199,
    "cost_per_call": 15,
    "weekly_spend": 17985,
    "deal_count": 11,
    "cpa": 1600,
    "ip": 18370,
    "ip_roi": 1.02,
    "avg_ip": 1670,
  }
];


export default function Calculator(props) {
  const gridRef = useRef();
  // Row Data: The data to be displayed.
  const [rowData, setRowData] = useState([]);
  const [startDate, setStartDate] = useState(begginingOfWeek());
  const [endDate, setEndDate] = useState(currentDay());

  // Update the date range based on the selected option
  const updateDateRange = (range) => {
    console.log("range: ", range);
    switch (range) {
      case "current_week":
        setStartDate(begginingOfWeek());
        setEndDate(currentDay());
        break;

      case "previous_week":
        setStartDate(begginingOfLastWeek());
        setEndDate(endOfLastWeek());
        break;

      case "current_month":
        setStartDate(begginingOfMonth());
        setEndDate(currentDay());
        break;

      case "previous_month":
        setStartDate(begginingOfLastMonth());
        setEndDate(endOfLastMonth());
        break;

      case "current_quarter":
        setStartDate(begginingOfQuarter());
        setEndDate(currentDay());
        break;

      case "ytd":
        setStartDate(begginingOfYear());
        setEndDate(currentDay());
        break;
    }
  };

  // Grid Options
  const gridOptions = {
    sideBar: true,
    rowGroupPanelShow: 'always',
    groupDefaultExpanded: 1,
    columnHoverHighlight: true,
    // groupIncludeFooter: true,
    groupIncludeTotalFooter: true,
    suppressAggFuncInHeader: true,
    autoGroupColumnDef: {
      cellRendererParams: {
        footerValueGetter: params => {
          const isRootLevel = params.node.level === -1;
          if (isRootLevel) {
            return 'Grand Total';
          }
          return `Sub Total (${params.value})`;
        },
      },
      headerName: 'Dialer',
    },
    getRowStyle: params => {
      // Grand Total row formatting
      if (params.node.group === true && params.node.level === -1) {
        return { background: '#3F91B8', color: 'white', fontWeight: 'bold' };
        // #b5c4ca
        // #99bac9
      }
      // Subtotal row formatting
      else if (params.node.group === true) {
        return { background: '#b5c4ca', fontWeight: 'bold' };
      }
    },
    autoSizeStrategy: {
      type: 'fitCellContents'
    },
  };

  // Column Definitions
  const colDefs = [
    { field: "channel" },
    { field: "vendor" },
    {
      field: "vendor_spend", headerName: "Vendor Spend", cellStyle: { textAlign: 'right' }, editable: true, valueFormatter: params => currencyFormatter(params.value, "$"),
      onCellValueChanged: (params) => {
        const updatedData = weeklySpendUpdate(params.data);
        updatedData.__manuallyEdited = true;
        params.node.setData(updatedData);
        params.api.refreshCells({
          force: true,
          rowNodes: [params.node],
          columns: [params.column.colId]
        });
      }
    },
    {
      field: "lead_count", headerName: "Lead Count", editable: true, onCellValueChanged: (params) => {
        const updatedData = leadCountUpdate(params.data);
        updatedData.__manuallyEdited = true;
        params.node.setData(updatedData);
        params.api.refreshCells({
          force: true,
          rowNodes: [params.node],
          columns: [params.column.colId]
        });
      }
    },
    { field: "cpc", headerName: "Cost Per Call", cellStyle: { textAlign: 'right' }, editable: false, valueFormatter: params => currencyFormatter(params.value, "$") },
    {
      field: "deal_count", headerName: "Deal Count", editable: true,
      onCellValueChanged: (params) => {
        const updatedData = dealCountUpdate(params.data);
        updatedData.__manuallyEdited = true;
        params.node.setData(updatedData);
        params.api.refreshCells({
          force: true,
          rowNodes: [params.node],
          columns: [params.column.colId]
        });
      }
    },
    {
      field: "cpa", editable: true, valueFormatter: params => currencyFormatter(params.value, "$"),
      cellStyle: { textAlign: 'right' },
      onCellValueChanged: (params) => {
        const updatedData = cpaUpdate(params.data);
        updatedData.__manuallyEdited = true;
        params.node.setData(updatedData);
        params.api.refreshCells({
          force: true,
          rowNodes: [params.node],
          columns: [params.column.colId]
        });
      }
    },
    {
      field: "initial", headername: "IP", editable: true, valueFormatter: params => currencyFormatter(params.value, "$"),
      cellStyle: { textAlign: 'right' },
      onCellValueChanged: (params) => {
        const updatedData = ipUpdate(params.data);
        updatedData.__manuallyEdited = true;
        params.node.setData(updatedData);
        params.api.refreshCells({
          force: true,
          rowNodes: [params.node],
          columns: [params.column.colId]
        });
      }
    },
    { field: "ip_roi", headerName: "IP ROI", editable: false },
    {
      field: "avg_ip", headerName: "Avg IP", editable: true, valueFormatter: params => currencyFormatter(params.value, "$"),
      onCellValueChanged: (params) => {
        const updatedData = avgIpUpdate(params.data);
        updatedData.__manuallyEdited = true;
        params.node.setData(updatedData);
        params.api.refreshCells({
          force: true,
          rowNodes: [params.node],
          columns: [params.column.colId]
        });
      }
    },
    {
      field: "roas_deal_count",
      headerName: "ROAS Deal Count",
      editable: false,
    },
    {
      field: "roas_initial", valueFormatter: params => currencyFormatter(params.value, "$"),
      cellStyle: { textAlign: 'right' },
      headerName: "ROAS Initial",
      editable: false,
    },
    // {
    //   field: "roas_total_payments", valueFormatter: params => currencyFormatter(params.value, "$"),
    //   align: 'right',
    //   headerName: "ROAS Total Payments",
    //   editable: false,
    // }
  ];

  // Cell Renderer for highlighting changes
  const CellRenderer = (params) => {
    const [highlighted, setHighlighted] = useState(false);
    const prevValueRef = useRef(params.value);

    useEffect(() => {
      if (params.value !== prevValueRef.current || params.node?.data?.__manuallyEdited) {
        setHighlighted(true);
        prevValueRef.current = params.value;
        if (params.node?.data) {
          params.node.data.__manuallyEdited = false;
        }
      }
    }, [params.value, params.node?.data?.__manuallyEdited]);

    const formattedValue = params.column.colDef.valueFormatter
      ? params.column.colDef.valueFormatter(params)
      : params.value;

    return (
      <div className={highlighted ? 'highlighted' : ''}>
        {formattedValue}
      </div>
    );
  };

  // Default column definitions
  const defaultColDef = {
    filter: true,
    cellRenderer: CellRenderer,
  };

  // Preserving Filter Model
  const [filterModel, setFilterModel] = useState(null);  // Save current filters

  // Loading state to track when data is being fetched
  const [loading, setLoading] = useState(true);

  // Fetch data based on date range
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Set loading to true before fetching
      if (gridRef.current) {
        // Save current filter state
        const currentFilterModel = gridRef.current.api.getFilterModel();
        setFilterModel(currentFilterModel);
      }

      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      };
      const reqUrl = `${process.env.REACT_APP_API_URL}/api/reports?report=calculator&startDate=${startDate}&endDate=${endDate}`;

      try {
        const result = await fetch(reqUrl, requestOptions);
        const gridData = await result.json();
        console.log("gridData.data: ", gridData.data);
        setRowData(gridData.data);
      } catch (error) {
        console.error("ERROR: " + error);
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    };

    fetchData();
  }, [startDate, endDate]);


  useEffect(() => {
    if (rowData.length > 0 && gridRef.current && gridRef.current.api) {
      gridRef.current.api.forEachNode((node) => {
        if (node.setDataValue) {
          Object.keys(node.data).forEach(key => {
            node.setDataValue(key, node.data[key]);
          });
        }
      });
    }
  }, [rowData]);

  return (
    <div>
      {/* Page Content */}
      <main>
        <div className="ag-theme-quartz h-60 m-top-3">
          <div id="summaryBox">
            <p id="summaryText">
              <strong>Volume Calculator</strong><br />
              This tool can be used to calculate expected volume based on each channels historical performance.
            </p>
          </div>
          {/* Box to hold the date range selection */}
          <div className="f justify-center blueBg report-header">
            <select className='large-font bold-text rounded'
              onChange={(e) => updateDateRange(e.target.value)}>
              <option value="current_week">Current Week</option>
              <option value="previous_week">Previous Week</option>
              <option value="current_month">Current Month</option>
              <option value="previous_month">Previous Month</option>
              <option value="current_quarter">Current Quarter</option>
              <option value="ytd">Year To Date</option>
            </select>
            <p className='m-left-2 bold-text white-text large-font'>
              {startDate} - {endDate}
            </p>
          </div>
          <AgGridReact
            animateRows={true}
            enableRangeSelection={true}
            gridRef={gridRef}
            rowData={rowData}
            columnDefs={colDefs}
            gridOptions={gridOptions}
            defaultColDef={defaultColDef}
            defaultSideBar={false}
            sideBar={{
              toolPanels: [
                {
                  id: 'columns',
                  labelDefault: 'Columns',
                  labelKey: 'columns',
                  iconKey: 'columns',
                  toolPanel: 'agColumnsToolPanel',
                },
                {
                  id: 'filters',
                  labelDefault: 'Filters',
                  labelKey: 'filters',
                  iconKey: 'filter',
                  toolPanel: 'agFiltersToolPanel',
                }
              ]
            }}
          />
          {/* If loading show spinner */}
          {loading && (
            <div className="loading-overlay">
              <div className="spinner"></div>
            </div>
          )}
        </div>
      </main>
    </div>
  )
}
