import React, { useState, useEffect, useRef, useCallback } from 'react';

import { AgGridReact } from 'ag-grid-react'; // AG Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import "ag-grid-enterprise";
import "ag-grid-charts-enterprise";
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';

import { dateFormatter, currencyFormatter } from '../valueformatter';

export default function LienReport(props) {
	const gridRef = useRef();
	const [rowData, setRowData] = useState([]);
	const gridOptions = {
			sideBar: true,
			rowGroupPanelShow: 'always',
			groupDefaultExpanded: 0,
			columnHoverHighlight: true,
			groupIncludeTotalFooter: true,
			suppressAggFuncInHeader: true,
			autoGroupColumnDef: {
					cellRendererParams: {
						footerValueGetter: params => {
							const isRootLevel = params.node.level === -1;
							console.log("isRootLevel: ", isRootLevel);
							if (isRootLevel) {
								return 'Grand Total';
							}
							return `Sub Total (${params.value})`;
						},
					},
					headerName: 'Filing Date',
				},
				getRowStyle: params => {
					// Grand Total row formatting
					if (params.node.group === true && params.node.level === -1) {
						return { background: '#3F91B8', color: 'white', fontWeight: 'bold' };
						// #b5c4ca
						// #99bac9
					}
					// Subtotal row formatting
					else if (params.node.group === true) {
						return { background: '#b5c4ca', fontWeight: 'bold' };
					}
				},
				autoSizeStrategy: {
					type: 'fitCellContents'
				},
	};

	const defaultColDef = {
			filter: true,
	};

	// Group agg func
	const getGroupRowAgg = useCallback((params) => {
			const result = {
					amount: 0,
					lien_count: 0,
					avg_amount: 0,
					max_amount: 0,
					min_amount: 0,
			}

			// Total up row values/make calcs
			params.nodes.forEach((node) => {
					const data = node.group ? node.aggData : node.data 
					// Increment Amount
					result.amount += data.amount;
					// Increment Lien Count
					result.lien_count += node.group ? data.lien_count : 1;
					// // Get Max Amount
					// if (data.amount > result.max_amount) {
					//     result.max_amount = data.amount;
					// }
					// // Get Min Amount
					// if (data.amount < result.min_amount) {
					//     result.min_amount = data.amount;
					// }
			});
			// console.log("Lien Count: ", result.lien_count);
			// console.log("Total Amount: ", result.amount);

			// Calc Average Amount
			if (result.lien_count !== 0) {
					result.amount = result.amount / result.lien_count;
			}

			return result;
	}, []);

	const colDefs = [
	{
		field: "filing_date",  headerName: "Filing Date",endableRowGroup: true, rowGroup: true, hide: true, sort: 'asc',
		valueFormatter: params => dateFormatter(params.value)
	},
	{ 
		field: "amount", cellStyle: {textAlign: 'right'},
		valueFormatter: params => currencyFormatter(params.value, '$') 
	},
	{   
		field: "lien_count", 
		headerName: "Lien Count", 
		cellStyle: {textAlign: 'right'}, 
		aggFunc: 'sum',
		valueGetter: params => params.node.group ? params.data.lien_count : 1
	},
	// {
	//     field: "avg_amount", headerName: "AVG Amount", cellStyle: { textAlign: 'right' },
	//     valueFormatter: params => currencyFormatter(params.value, "$"),
	//     valueGetter: params => adservRatio(params.data.amount, params.data.lien_count) // Need to set the valueGetter to calc each row
	// },
	// {
	//     field: "max_amount", headerName: "Max Amount", cellStyle: { textAlign: 'right' },
	//     valueFormatter: params => currencyFormatter(params.value, "$"),
	//     // valueGetter: params => adservRatio(params.data.amount, params.data.lien_count) // Need to set the valueGetter to calc each row
	// },
	// {
	//     field: "min_amount", headerName: "Min Amount", cellStyle: { textAlign: 'right' },
	//     valueFormatter: params => currencyFormatter(params.value, "$"),
	//     // valueGetter: params => adservRatio(params.data.amount, params.data.lien_count) // Need to set the valueGetter to calc each row
	// },
	];

  // Preserving Filter Model
  const [filterModel, setFilterModel] = useState(null);  // Save current filters

  // Loading state to track when data is being fetched
  const [loading, setLoading] = useState(true);

  // Fetch data based on date range
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Set loading to true before fetching
      if (gridRef.current) {
        // Save current filter state
        const currentFilterModel = gridRef.current.api.getFilterModel();
        setFilterModel(currentFilterModel);
      }

      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      };
      const reqUrl = `${process.env.REACT_APP_API_URL}/api/reports?report=${props.reportName}&startDate=${props.startDate}&endDate=${props.endDate}`;

      try {
        const result = await fetch(reqUrl, requestOptions);
        const gridData = await result.json();
        setRowData(gridData.data);
      } catch (error) {
        console.error("ERROR: " + error);
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    };

    fetchData();
  }, [props.reportName, props.startDate, props.endDate]);

  // After new data is set, reapply the filter model
  useEffect(() => {
    if (gridRef.current && filterModel) {
      gridRef.current.api.setFilterModel(filterModel);
    }
  }, [rowData]);

  // If rows have not yet been returned
  // if (loading) {
  //   return (
  //     <div>
  //       <h1 className='color2-font'>DATA LOADING</h1>
  //     </div>
  //   )
  // }

	return (
		// wrapping container with theme & size 
		<div
			className='ag-theme-quartz h-60 m-top-3 greyBg' // applying the grid theme
		>
			<div id='summaryBox'>
				<p id='summaryText'>
					<strong>Lien Report</strong><br />
					This view will show tax lien filings within the filtered date range.< br/>
					The 'Amount' subtotal rows is showing the average lien amount for each day, but when expanded it has the amount for each lien.< br/>
					The 'Lien Count' shows how many liens were filed for each day.< br/>
				</p>
			</div>
			<div style={{ position: 'relative', height: '100%' }}> {/* New wrapper for AG Grid */}
				<AgGridReact
					enableCharts={true}
					enableRangeSelection={true}
					gridRef={gridRef}
					rowData={rowData}
					columnDefs={colDefs}
					getGroupRowAgg={getGroupRowAgg}
					gridOptions={gridOptions}
					defaultColDef={defaultColDef}
					modules={[ServerSideRowModelModule, RowGroupingModule]}
				/>
				{loading && (
        <div className="loading-overlay">
          <div className="spinner"></div>
        </div>
      )}
    </div>
		</div>
	)
};
