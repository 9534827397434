import React, { useState, useEffect, useRef, useCallback } from 'react';

import { AgGridReact } from 'ag-grid-react'; // AG Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import "ag-grid-enterprise";
import "ag-grid-charts-enterprise";
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';

export default function CCCallData(props) {
	const gridRef = useRef();
	const gridOptions = {
		sideBar: true,
		rowGroupPanelShow: 'always',
		groupDefaultExpanded: 0,
		columnHoverHighlight: true,
		groupIncludeTotalFooter: true,
		suppressAggFuncInHeader: true,
		autoGroupColumnDef: {
            sort: 'asc',
			cellRendererParams: {
				footerValueGetter: params => {
					const isRootLevel = params.node.level === -1;
					console.log("isRootLevel: ", isRootLevel);
					if (isRootLevel) {
						return 'Grand Total';
					}
					return `Sub Total (${params.value})`;
				},
			},
			// headerName: 'Filing Date',
		},
		getRowStyle: params => {
			// Grand Total row formatting
			if (params.node.group === true && params.node.level === -1) {
				return { background: '#3F91B8', color: 'white', fontWeight: 'bold' };
				// #b5c4ca
				// #99bac9
			}
			// Subtotal row formatting
			else if (params.node.group === true) {
				return { background: '#b5c4ca', fontWeight: 'bold' };
			}
		},
		autoSizeStrategy: {
			type: 'fitCellContents'
		},
	};

	const defaultColDef = {
		filter: true,
	};

	// Group agg func
	const getGroupRowAgg = useCallback((params) => {
		const result = {
			contact_start: 0,
		}

		// Total up row values/make calcs
		params.nodes.forEach((node) => {
			const data = node.group ? node.aggData : node.data
			// Increment Amount
            result.contact_start += node.group ? data.contact_start : 1;
		});
		// console.log("Total Amount: ", result.amount);

		// Calc Average Amount
		// if (result.lien_count !== 0) {
		// 	result.amount = result.amount / result.lien_count;
		// }

		return result;
	}, []);

	const colDefs = [
        {
            field: "contact_start", headerName: "Contact Start", enableRowGroup: true, sort: 'asc',
            cellStyle: params => { 
                if (params.node.group){
                    return { textAlign: 'right' }
                }
            },
			valueFormatter: params => {
				if (!params.node.group) {
					return new Date(params.value).toLocaleString('en-US', { 
						timeZone: 'America/Los_Angeles',
						timeZoneName: 'short', // Shows either PST or PDT
						month: '2-digit',
						day: '2-digit',
						year: 'numeric',
						hour: '2-digit',
						minute: '2-digit',
						second: '2-digit',
						hour12: true
					})
				} else {
					return params.value;
				}
				
            },
            headerTooltip: "Datetime the call was received",
                tooltipValueGetter: (params) => {
                    if (params.node.group) {
                        return "Total number of calls"
                    } else {
                        return "Datetime the call was received"
                    }
                },      
        },
		{
			field: "point_of_contact_name", headerName: "POC Name", enableRowGroup: true,
			// valueFormatter: params => currencyFormatter(params.value, '$')
		},
        {
			field: "from_addr", headerName: "ANI", enableRowGroup: true,
            cellStyle: { textAlign: 'right' },
			// valueFormatter: params => currencyFormatter(params.value, '$')
		},
        {
			field: "to_addr", headerName: "DNIS", enableRowGroup: true,
            cellStyle: { textAlign: 'right' },
			// valueFormatter: params => currencyFormatter(params.value, '$')
		},
        {
			field: "agent_name", headerName: "Agent Name", enableRowGroup: true,
			// valueFormatter: params => currencyFormatter(params.value, '$')
		},
		{
			field: "in_queue_seconds", headerName: "In Queue Seconds", enableRowGroup: true, cellStyle: { textAlign: 'right' },
			filter: "agNumberColumnFilter",
			// valueFormatter: params => currencyFormatter(params.value, '$')
		},
        {
			field: "agent_seconds", headerName: "Agent Seconds", enableRowGroup: true,
            cellStyle: { textAlign: 'right' },
			filter: "agNumberColumnFilter",
			// valueFormatter: params => currencyFormatter(params.value, '$')
		},
        {
			field: "total_duration_seconds", headerName: "Total Seconds", enableRowGroup: true,
            cellStyle: { textAlign: 'right' },
			filter: "agNumberColumnFilter",
			// valueFormatter: params => currencyFormatter(params.value, '$')
		},
        {
			field: "disposition_name", headerName: "Disposition", enableRowGroup: true,
			// valueFormatter: params => currencyFormatter(params.value, '$')
		},
        {
			field: "disposition_notes", headerName: "Disposition Notes", enableRowGroup: true,
			// valueFormatter: params => currencyFormatter(params.value, '$')
		},
        {
			field: "end_reason", headerName: "End Reason", enableRowGroup: true,
			// valueFormatter: params => currencyFormatter(params.value, '$')
		},
        {
			field: "abandoned", headerName: "Abandoned", enableRowGroup: true,
            cellStyle: { justifyContent: 'center', display: 'flex',},
			// valueFormatter: params => currencyFormatter(params.value, '$')
		},
        // {
		// 	field: "jira_issue_key", headerName: "Jira Issue Key", enableRowGroup: true,
		// 	// valueFormatter: params => currencyFormatter(params.value, '$')
		// },
        // {
		// 	field: "logics_case_number", headerName: "Logics Case Number", enableRowGroup: true,
		// 	// valueFormatter: params => currencyFormatter(params.value, '$')
		// },
		{
			field: "recording", headerName: "Recording", enableRowGroup: true,
			cellRenderer: params => {
				// Don't display the link if the data is grouped
				if (params.node.group) {
					return null;
				}
				const handleClick = (e) => {
					e.preventDefault();
					window.open(`https://home-c52.nice-incontact.com/inContact/Manage/Reports/ContactDetail.aspx?contactid=${params.data.contact_id}&mastercontactid=${params.data.master_contact_id}`, 
						'_blank', 
						'width=800,height=600'
					);
				};
		
				return (
					<a 
						href="#"
						onClick={handleClick}
					>
						Recording
					</a>
				);
			}
		},
		{
			field: "campaign_name", headerName: "Campaign Name", enableRowGroup: true,
			// valueFormatter: params => currencyFormatter(params.value, '$')
		},
	];

	// // Preserving Filter Model
	// const [filterModel, setFilterModel] = useState(null);  // Save current filters


    // Autosize the grouped column when the grid is first rendered
    const onFirstDataRendered = (params) => {
        setTimeout(() => {
            params.columnApi.autoSizeAllColumns();
        },100);
    };

    // Autosizes all columns when row group is expanded
    const onRowGroupOpened = (params) => {
        setTimeout(() => {
            params.columnApi.autoSizeAllColumns();
        },100);
    };

    // Autosizes all columns when row group is expanded
    const onColumnRowGroupChanged = (params) => {
        setTimeout(() => {
            params.columnApi.autoSizeAllColumns();
            // Get the row group columns
            const rowGroupCols = params.columnApi.getRowGroupColumns();
            if (rowGroupCols.length > 0) {
                // Get the last added group column
                const lastGroupCol = rowGroupCols[rowGroupCols.length - 1];
                // Apply sort to the grouped column
                params.columnApi.applyColumnState({
                    state: [{ colId: lastGroupCol.getColId(), sort: 'asc' }]
                });
            }
        }, 100);
    };

	// NOTE: TOOK FOREVER TO FIGURE THIS OUT but I figured it out...basically I was trying to test the filter 
	// for agent_name is equal to Dave Warren but it was failing to apply b/c it was a 'set' type of filter, not a 'text'.
	// // The default filterType for the columns is 'set' since when you click on the filter icon,
	// it shows a set of items to select from. You can change it to filterType 'text', 'number' or 'date' 
	// but you need to update the column definition with the corresponding property:
	// 	'filter: "agTextColumnFilter",
	// 	'filter: "agNumberColumnFilter",
	// 	'filter: "agDateColumnFilter",
    const filterQueuedCalls = () => {
		const currentFilterModel = gridRef.current.api.getFilterModel();
		if (currentFilterModel['in_queue_seconds']) {
			// remove it
			delete currentFilterModel['in_queue_seconds'];
			// Update filter model
			gridRef.current.api.setFilterModel(currentFilterModel);
		} else {
			gridRef.current.api.setFilterModel({
				// 'agent_name': {
				// 	// 'type': 'equals',
				// 	'values': ['Dave Warren'],
				// 	'filterType': 'set'
				// }
				'in_queue_seconds': {
					'type': 'notEqual',
					'filter': '0',
					'filterType': 'number'
				}
			});
	
		}

		
		// gridRef.current.api.onFilterChanged();

		// Print the current filter model
		console.log('Current Filter Model:', gridRef.current.api.getFilterModel());
		console.log('Grid ref api row count:', gridRef.current.api.getDisplayedRowCount());
		console.log('Grid ref api:', gridRef.current.api);
	};

	return (
		// wrapping container with theme & size 
		<div
			className='ag-theme-quartz h-75 greyBg' // applying the grid theme
		>
			<button onClick={filterQueuedCalls}>Filter Queued Calls</button>
			<div id='summaryBox'>
				<p id='summaryText'>
					<strong>Contact Center Call Data</strong><br />
					This view shows raw calls from Contact Center within the filtered date range.< br />
				</p>
			</div>
			<div style={{ position: 'relative', height: '100%' }}> {/* New wrapper for AG Grid */}
				<AgGridReact
                    onFirstDataRendered={onFirstDataRendered}
                    onRowGroupOpened={onRowGroupOpened}
                    onColumnRowGroupChanged={onColumnRowGroupChanged}
					enableCharts={true}
					enableRangeSelection={true}
					ref={gridRef}
					rowData={props.rowData}
					columnDefs={colDefs}
					getGroupRowAgg={getGroupRowAgg}
					gridOptions={gridOptions}
					defaultColDef={defaultColDef}
					modules={[ServerSideRowModelModule, RowGroupingModule]}
					defaultSideBar={false}
					sideBar={{
						toolPanels: [
							{
								id: 'columns',
								labelDefault: 'Columns',
								labelKey: 'columns',
								iconKey: 'columns',
								toolPanel: 'agColumnsToolPanel',
							},
							{
								id: 'filters',
								labelDefault: 'Filters',
								labelKey: 'filters',
								iconKey: 'filter',
								toolPanel: 'agFiltersToolPanel',
							}
						]
					}}
				/>
				{props.loading && (
					<div className="loading-overlay">
						<div className="spinner"></div>
					</div>
				)}
			</div>
		</div>
	)
};
