import React, { useState, useRef, useEffect } from 'react';

import { AgGridReact } from 'ag-grid-react'; // AG Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import './CashFlow.css';
import { currencyFormatter, adservRatio } from '../valueformatter';
import "ag-grid-enterprise";
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';

export default function CashFlow(props) {
  const gridRef = useRef(); // Optional - for accessing Grid's API
  // Row Data: The data to be displayed.
  const [rowData, setRowData] = useState([]);
  // Grid Options
  const gridOptions = {
    sideBar: true,
    rowGroupPanelShow: 'always',
    // groupDefaultExpanded: 1,
    columnHoverHighlight: true,
    // groupIncludeFooter: true,
    groupIncludeTotalFooter: true,
    suppressAggFuncInHeader: true,
    autoGroupColumnDef: {
      cellRendererParams: {
        footerValueGetter: params => {
          const isRootLevel = params.node.level === -1;
          console.log("isRootLevel: ", isRootLevel);
          if (isRootLevel) {
            return 'Grand Total';
          }
          return `Sub Total (${params.value})`;
        },
      },
      headerName: 'Logics',
    },
    getRowStyle: params => {
      // Grand Total row formatting
      if (params.node.group === true && params.node.level === -1) {
        return { background: '#3F91B8', color: 'white', fontWeight: 'bold' };
      }
      // Subtotal row formatting
      else if (params.node.group === true) {
        return { background: '#b5c4ca', fontWeight: 'bold' };
      }
    },
    autoSizeStrategy: {
      type: 'fitCellContents'
    },
  };

  // DefaultColDef sets props common to all Columns
  const defaultColDef = {
    filter: true,
    cellStyle: { textAlign: 'right' }
  };

  // Group agg func
  const getGroupRowAgg = (params) => {
    const result = {
      op_amount: 0,
      as_amount: 0,
      total: 0,
      ap_ratio: 0,
    }
    // Total up row values/make calcs
    params.nodes.forEach((node) => {
      const data = node.group ? node.aggData : node.data
      // Increment op amount
      result.op_amount += data.op_amount;
      // Increment as amount
      result.as_amount += data.as_amount;
      // Increment total
      result.total += data.total;
    });

    // ap ratio
    if (result.op_amount !== 0) {
      result.ap_ratio = adservRatio(result.as_amount, result.op_amount);
    }

    return result;
  }
  /*
    Row Object Example
    {
      "logics":"GTRL",
      "op_amount":"2000",
      "ap_amount":"3000",
      "total":"5000",
    }
  */

  // Column Definitions: Defines the columns to be displayed.
  const colDefs = [
    {
      field: "logics", sort: 'asc',
      cellStyle: { textAlign: 'left' }, enableRowGroup: true, rowGroup: true, hide: true,
    },
    {
      field: "op_amount", headerName: "Origination Payments", aggFunc: 'sum',
      valueFormatter: params => currencyFormatter(params.value, "$"),
    },
    {
      field: "as_amount", headerName: "Adserv Payments", aggFunc: 'sum',
      valueFormatter: params => currencyFormatter(params.value, "$"),
    },
    {
      field: "total", headerName: "Total Payments",
      valueFormatter: params => currencyFormatter(params.value, "$"),
    },
    {
      field: "ap_ratio", headerName: "AS Ratio",
      valueGetter: params => adservRatio(params.data.as_amount, params.data.op_amount),
      // valueFormatter: params => percentageFormatter(params.value),
    },
  ];

  // Preserving Filter Model
  const [filterModel, setFilterModel] = useState(null);  // Save current filters

  // Loading state to track when data is being fetched
  const [loading, setLoading] = useState(true);

  // Fetch data based on date range
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Set loading to true before fetching
      if (gridRef.current) {
        // Save current filter state
        const currentFilterModel = gridRef.current.api.getFilterModel();
        setFilterModel(currentFilterModel);
      }

      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      };
      const reqUrl = `${process.env.REACT_APP_API_URL}/api/reports?report=${props.reportName}&startDate=${props.startDate}&endDate=${props.endDate}`;

      try {
        const result = await fetch(reqUrl, requestOptions);
        const gridData = await result.json();
        setRowData(gridData.data);
      } catch (error) {
        console.error("ERROR: " + error);
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    };

    fetchData();
  }, [props.reportName, props.startDate, props.endDate]);

  // After new data is set, reapply the filter model
  useEffect(() => {
    if (gridRef.current && filterModel) {
      gridRef.current.api.setFilterModel(filterModel);
    }
  }, [rowData]);

  // // If rows have not yet been returned
  // if (rowData.length === 0) {
  //   return (
  //     <div>
  //       <h1 className='color2-font'>DATA LOADING</h1>
  //     </div>
  //   )
  // }

  return (
    // wrapping container with theme & size
    <div
      className="ag-theme-quartz h-60 m-top-3 greyBg" // applying the grid theme
    // style={{ height: 100% }} // the grid will fill the size of the parent container
    >
      <div id="summaryBox">
        <p id="summaryText">
          <strong>Cash Flow</strong><br />
          This report provides a comprehensive view of the financial transactions related to Origination Payments and Adserv Payments, along with insights into their ratios.
          {/* Origination Payments: This column represents the total amount of money received from origination sources. It includes payments from initial transactions or deals.
          Adserv Payments: This column showcases the total amount of payments received from advertising services. It encompasses revenue generated from ad-related activities.
          Total Payments: This column calculates the overall sum of Origination Payments and Adserv Payments, providing a consolidated view of cash inflows.
          AS Ratio (Adserv Ratio): This column calculates the ratio between Adserv Payments and Origination Payments. It helps assess the proportion of revenue generated from advertising services compared to the total revenue from origination sources. */}
          {/* <br/>
          <ul>
            <li>Payment totals are filtered via paid date and are <strong>independent</strong> of the sale date of the case.</li>
            <li>Splits are also taken into consideration to provide a more accurate credit to each Settlement Officer.</li>
          </ul> */}
        </p>
      </div>
      <div style={{ position: 'relative', height: '100%' }}> {/* New wrapper for AG Grid */}
        <AgGridReact
          enableCharts={true}
          enableRangeSelection={true}
          gridRef={gridRef}
          rowData={rowData}
          columnDefs={colDefs}
          getGroupRowAgg={getGroupRowAgg}
          gridOptions={gridOptions}
          defaultColDef={defaultColDef}
          modules={[ServerSideRowModelModule, RowGroupingModule]}
          defaultSideBar={false}
          sideBar={{
            toolPanels: [
              {
                id: 'columns',
                labelDefault: 'Columns',
                labelKey: 'columns',
                iconKey: 'columns',
                toolPanel: 'agColumnsToolPanel',
              },
              {
                id: 'filters',
                labelDefault: 'Filters',
                labelKey: 'filters',
                iconKey: 'filter',
                toolPanel: 'agFiltersToolPanel',
              }
            ]
          }}
        />
        {loading && (
          <div className="loading-overlay">
            <div className="spinner"></div>
          </div>
        )}
      </div>
    </div>
  )
}