import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react';

import { AgGridReact } from 'ag-grid-react'; // AG Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import './OpenerReport.css'
import "ag-grid-enterprise";
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';

import { currencyFormatter, cleanNulls, percentageFormatter, percentageOf } from '../valueformatter';

export default function OpenerReport(props) {
  const [gridOptions] = useState({
    sideBar: true,
    rowGroupPanelShow: 'always',
    // groupDefaultExpanded: 1,
    columnHoverHighlight: true,
    // groupIncludeFooter: true,
    groupIncludeTotalFooter: true,
    suppressAggFuncInHeader: true,
    autoGroupColumnDef: {
      cellRendererParams: {
        footerValueGetter: params => {
          const isRootLevel = params.node.level === -1;
          // console.log("isRootLevel: ", isRootLevel);
          if (isRootLevel) {
            return 'Grand Total';
          }
          return `Sub Total (${params.value})`;
        },
      },
      headerName: 'Agent',
    },
    getRowStyle: params => {
      // Grand Total row formatting
      if (params.node.group === true && params.node.level === -1) {
        return { background: '#3F91B8', color: 'white', fontWeight: 'bold' };
        // #b5c4ca
        // #99bac9
      }
      // Subtotal row formatting
      else if (params.node.group === true) {
        return { background: '#b5c4ca', fontWeight: 'bold' };
      }
    },
    autoSizeStrategy: {
      type: 'fitCellContents'
    },
  });

  const gridRef = useRef(); // Optional - for accessing Grid's API

  // DefaultColDef sets props common to all Columns
  const defaultColDef = useMemo(() => ({
    filter: true,
  }), []);

  // Group agg func
  const getGroupRowAgg = useCallback((params) => {
    const result = {
      calls: 0,
      transfer_rate: 0,
      transferred: 0,
      transferred_to_sales: 0,
      transferred_to_trc: 0,
      transferred_to_cs: 0,
      cases_created: 0,
      deals: 0,
      initial_payment: 0,
      total_payment: 0,
      gross_sale: 0,
    }
    // Total up row values/make calcs
    params.nodes.forEach((node) => {
      const data = node.group ? node.aggData : node.data
      // Increment Call Count
      result.calls += data.calls;
      // Increment Transferred
      result.transferred += data.transferred;
      // Increment Transferred to Sales
      result.transferred_to_sales += data.transferred_to_sales;
      // Increment Cases Created
      result.transferred_to_trc += data.transferred_to_trc;
      // Increment Cases Created
      result.transferred_to_cs += data.transferred_to_cs;
      // Increment Cases Created
      result.cases_created += data.cases_created;
      // Increment Deals
      result.deals += data.deals;
      // Increment IP
      result.initial_payment += data.initial_payment;
      // Increment TP
      result.total_payment += data.total_payment;
      // Increment GS
      result.gross_sale += data.gross_sale;
    });

    // Calc Transfer Rate
    if (result.calls !== 0) {
      result.transfer_rate = parseFloat((result.transferred_to_sales / result.calls).toFixed(2) * 100);
    }

    return result;
  }, []);


  console.log("props: ", props);
  /*
    Row Object Example
    {
      "agent_name":"Vanessa Oakes",
      "team_name":"VTG - Virtual",
      "date":"2024-03-26",
      "transferred":48,
      "transferred_to_sales":48,
      "transferred_to_trc":48,
      "transferred_to_cs":48,
    }
  */

  // Column Definitions: Defines the columns to be displayed.
  const [colDefs] = useState([
    { field: "agent_name", headerName: "Agent", sort: "asc", enableRowGroup: true, },
    // { field: "team_name", headerName: "Team", sort: "asc",  enableRowGroup: true,
    //   cellClassRules: {
    //     // apply green to 2008
    //     'vtg-color': params => params.value === 'VTG - Virtual',
    //     // apply blue to 2004
    //     'opener-color': params => params.value === 'Openers',
    //     // apply red to 2000
    //   }  
    // },
    // { field: "date", headerName: "Date", sort: "asc", enableRowGroup: true,
    // valueFormatter: params => dateFormatter(params.value),  
    // valueGetter: params => dateFormatter(params.value),
    // },
    { field: "calls", headerName: "Calls", cellStyle: { textAlign: 'right' }, aggFunc: 'sum', },
    {
      field: "transfer_rate",
      headerName: "Transfer To Sales %",
      valueGetter: params => percentageOf(params.data.transferred_to_sales, params.data.calls),
      valueFormatter: params => percentageFormatter(params.value),
      cellStyle: { textAlign: 'right' },
    },
    { field: "transferred", headerName: "Transferred", cellStyle: { textAlign: 'right' }, aggFunc: 'sum', },
    { field: "transferred_to_sales", headerName: "Transferred to Sales", cellStyle: { textAlign: 'right' }, aggFunc: 'sum', },
    { field: "transferred_to_trc", headerName: "Transferred to TRC", cellStyle: { textAlign: 'right' }, aggFunc: 'sum', },
    { field: "transferred_to_cs", headerName: "Transferred to CS", cellStyle: { textAlign: 'right' }, aggFunc: 'sum', },
    { field: "cases_created", headerName: "Cases Created", cellStyle: { textAlign: 'right' }, aggFunc: 'sum' },
    { field: "deals", headerName: "Deals", cellStyle: { textAlign: 'right' }, aggFunc: 'sum', },
    {
      field: "initial_payment", headerName: "Initial Payment", cellStyle: { textAlign: 'right' }, aggFunc: 'sum',
      valueFormatter: params => currencyFormatter(params.value, "$")
    },
    {
      field: "total_payment", headerName: "Total Payment", cellStyle: { textAlign: 'right' }, aggFunc: 'sum',
      valueFormatter: params => currencyFormatter(params.value, "$")
    },
    {
      field: "gross_sale", headerName: "Gross Sale", cellStyle: { textAlign: 'right' }, aggFunc: 'sum',
      valueFormatter: params => currencyFormatter(params.value, "$")
    },
  ]);

  // Row Data: The data to be displayed.
  const [rowData, setRowData] = useState([]);

  // Preserving Filter Model
  const [filterModel, setFilterModel] = useState(null);  // Save current filters

  // Loading state to track when data is being fetched
  const [loading, setLoading] = useState(true);

  // Fetch data based on date range
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Set loading to true before fetching
      if (gridRef.current) {
        // Save current filter state
        const currentFilterModel = gridRef.current.api.getFilterModel();
        setFilterModel(currentFilterModel);
      }

      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      };
      const reqUrl = `${process.env.REACT_APP_API_URL}/api/reports?report=${props.reportName}&startDate=${props.startDate}&endDate=${props.endDate}`;

      try {
        const result = await fetch(reqUrl, requestOptions);
        const gridData = await result.json();
        setRowData(gridData.data);
      } catch (error) {
        console.error("ERROR: " + error);
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    };

    fetchData();
  }, [props.reportName, props.startDate, props.endDate]);

  // After new data is set, reapply the filter model
  useEffect(() => {
    if (gridRef.current && filterModel) {
      gridRef.current.api.setFilterModel(filterModel);
    }
  }, [rowData]);

  // If rows have not yet been returned
  // if (loading) {
  //   return (
  //     <div>
  //       <h1 className='color2-font'>DATA LOADING</h1>
  //     </div>
  //   )
  // }

  return (
    // wrapping container with theme & size
    <div
      className="ag-theme-quartz h-60 m-top-3 greyBg" // applying the grid theme
    // style={{ height: 100% }} // the grid will fill the size of the parent container
    >
      <div id="summaryBox">
        <p id="summaryText">
          <strong>Opener Report</strong><br />
          This view will show data for the Openers. More specifically, it shows, calls, deals, transfers, and payment information.
          <ul>
            <li>The <strong>Transfer to Sales %</strong> is using the <strong>Transferred to Sales</strong> column; not the <strong>Transferred</strong> column</li>
          </ul>
        </p>
      </div>
      <div style={{ position: 'relative', height: '100%' }}> {/* New wrapper for AG Grid */}
        <AgGridReact
          rowData={rowData}
          columnDefs={colDefs}
          getGroupRowAgg={getGroupRowAgg}
          gridOptions={gridOptions}
          defaultColDef={defaultColDef}
          modules={[ServerSideRowModelModule, RowGroupingModule]}
          defaultSideBar={false}
          sideBar={{
            toolPanels: [
              {
                id: 'columns',
                labelDefault: 'Columns',
                labelKey: 'columns',
                iconKey: 'columns',
                toolPanel: 'agColumnsToolPanel',
              },
              {
                id: 'filters',
                labelDefault: 'Filters',
                labelKey: 'filters',
                iconKey: 'filter',
                toolPanel: 'agFiltersToolPanel',
              }
            ]
          }}
        />
        {loading && (
          <div className="loading-overlay">
            <div className="spinner"></div>
          </div>
        )}
      </div>
    </div>
  )
}